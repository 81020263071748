import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function StakingHistory({ sample }) {
    const [selectedFund, setSelectedFund] = useState({});
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const showSingleModel = (row) => {
        setSelectedFund(row);
        setShow(true);
    };

    const columns = [
        {
            name: 'ID',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Plan',
            selector: (row) => row?.coinId?.name,
            sortable: true,
        },
        {
            name: 'Quantity',
            selector: (row) => `${row?.amount.toFixed(2)} metabt`,
            sortable: true,
        },
        {
            name: 'Daily Quantity',
            selector: (row) => `${row?.dailyamount.toFixed(2)} metabt`,
            sortable: true,
        },
        {
            name: 'Daily Reward',
            selector: (row) => `${row?.dailypercetange} %`,
            sortable: true,
        },
        {
            name: 'Date and Time',
            selector: (row) => new Date(row.creditDate).toLocaleDateString(),
            sortable: true,
        },
        {
            cell: (row) => (
                <Button variant="primary" onClick={() => showSingleModel(row)}>
                    View Details
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <div className='table-responsive'>
            <DataTable
                columns={columns}
                data={sample}
                pagination
                highlightOnHover
                noDataComponent={<p className="mt-3" style={{ textAlign: "center" }}>No Record Found</p>}
            />
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className='pb-0'><h4 className='mb-0'>Staking History</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        <table className='table table-striped table-hover'>
                            <tbody className='text-nowrap'>
                                <tr>
                                    <td><b>Name</b></td>
                                    <td>{selectedFund?.userId?.username}</td>
                                </tr>
                                <tr>
                                    <td><b>Email</b></td>
                                    <td>{selectedFund?.userId?.email}</td>
                                </tr>
                                <tr>
                                    <td><b>Package</b></td>
                                    <td>{selectedFund?.coinId?.name}</td>
                                </tr>
                                <tr>
                                    <td><b>Amount</b></td>
                                    <td>{selectedFund?.amount} metabt</td>
                                </tr>
                                <tr>
                                    <td><b>Daily Amount</b></td>
                                    <td>{selectedFund?.dailyamount} metabt</td>
                                </tr>
                                <tr>
                                    <td><b>Daily %</b></td>
                                    <td>{selectedFund?.dailypercetange} %</td>
                                </tr>
                                <tr>
                                    <td><b>Credit Date</b></td>
                                    <td>{new Date(selectedFund?.creditDate).toLocaleDateString()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer className='pt-0'>
                    <Button onClick={handleClose} className='border-0 bg-danger' style={{ background: '#e4141696 !important' }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
