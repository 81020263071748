import React, { useEffect } from 'react'
import { createBrowserRouter, Outlet, RouterProvider, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DashboardPage from './Page/Home/DashboardPage';
import HeaderComponent from './Components/Common/HeaderComponent';
import SideBarComponents from './Components/Common/SideBarComponents';
import LoginPage from './Page/Login/LoginPage';
import { useSelector } from 'react-redux';

import SettingsPage from './Page/Settings/SettingsPage';
import 'react-toastify/dist/ReactToastify.css';

import AddCategory from './Page/Blog/Category/AddCategory.jsx';
import UploadedAttachmentsTable from './Page/UploadedAttachmentsTable';


import AddBlog from './Page/Blog/Category/AddBlog.jsx';
import BlogListPage from './Page/Blog/Category/BlogListPage.jsx';
import AddTag from './Page/Blog/Category/AddTag.jsx';
import CoinPage from './Page/Coin/CoinPage.jsx';
import UserPage from './Page/User/UserPage.jsx';
import RolesPage from './Page/Roles/RolesPage.jsx';
import UserRolePage from './Page/User/UserRolePage.jsx';
import UserForm from './Forms/User/UserForm.jsx';
import ImportUsers from './Forms/ImportUsers.jsx';
import FundPage from './Page/Fund/FundPage.jsx';
import SellHistoryPage from './Page/Coin/SellHistoryPage.jsx';
import BuyHistoryPage from './Page/Coin/BuyHistoryPage.jsx';
import StakingRewardPage from './Page/Income/StakingRewardPage.jsx';
import PlanPage from './Page/Plan/PlanPage.jsx';
import RegisterPage from './Page/Login/RegisterPage.jsx';
import SidebarComponent from './BtCash/common/SidebarComponent.jsx';
import UserHeaderComponent from './BtCash/common/HeaderComponent.jsx';
import ProfilePage from './BtCash/Page/User/ProfilePage.jsx';
import BuyPlanPage from './BtCash/Page/BuyPlan/BuyPlanPage.jsx';
import UserDashboardPage from './BtCash/Page/Dashboard/DashboardPage.jsx';
import TransactionPage from './BtCash/Page/Transaction/TransactionPage.jsx';
import WalletPage from './BtCash/Page/Wallet/WalletPage.jsx';
import StakingPage from './BtCash/Page/Staking/StakingPage.jsx';
import FooterComponent from './BtCash/common/FooterComponent.jsx';
import SupportPage from './BtCash/Page/Support/SupportPage.jsx';
import VerifyAccount from './Page/Login/VerifyAccount.jsx';
import ResetPassword from './Page/Login/ResetPassword.jsx';
import ForgetPassword from './Page/Login/ForgetPassword.jsx';
import TreePage from './Page/Tree/TreePage.jsx';
import Network from './BtCash/Page/Network/Network.jsx';
import MyTeam from "./BtCash/Page/Team/MyTeam.jsx";
import FundTransferPage from './BtCash/Page/FundTransfer/FundTransferPage.jsx';
import Deposit from './Page/Deposit/Deposit.jsx';
import Withdrawls from './Page/Withdrawls/Withdrawls.jsx';
import AffiliateRewardListComponent from './Components/Income/AffiliateRewardListComponent.jsx';
import StakingListComponent from './Components/Income/StakingListComponent.jsx';
import RoyaltyRewardListComponent from './Components/Income/RoyaltyRewardListComponent.jsx';
import { deactiveplan, sendLiveMoneyFromUserToAdmin } from './common/Api.jsx';
import NotFoundPage from './Page/NotFoundPage'; // Import the NotFoundPage
import NewPassword from './Page/Login/NewPassword.jsx';
import NetworkLevel from './BtCash/Page/Network/NetworkLevel.jsx';
import DirectReferal from './BtCash/Page/Network/DirectReferal.jsx';
import TestingDashboardPage from './BtCash/Page/Dashboard/TestingDashboardPage.jsx';


const HomePage = () => {
  return (
    <iframe
      src="/home-page/index.html"
      style={{ width: '100%', height: '100vh', border: 'none' }}
      title="Home Page"
    />
  );
};

const PrivacyPolicy = () => {
  return (
    <iframe
      src="/home-page/privacy-policy.html"
      style={{ width: '100%', height: '100vh', border: 'none' }}
      title="Home Page"
    />
  );
};


const Layout = () => {
  const navigate = useNavigate()
  const { user, token } = useSelector((state) => state.user)
  // console.log(user)

  useEffect(() => {
    const getDataUserActive = async () => {
      await deactiveplan(user._id, token)
    }
    getDataUserActive()
    //sendLiveMoneyFromUserToAdmin(user.btwallet, user.btkey)
  }, [user._id, token, user.btwallet, user.btkey])

  return (
    <>
      <ToastContainer position="bottom-center" />
      {
        user.isAdmin == 'admin' || user.isAdmin == 'sub-admin' ?
          <div className='layout-wrapper layout-content-navbar'>
            <div className="layout-container">
              <SideBarComponents />
              <div className="layout-page">
                <HeaderComponent />
                <div className="content-wrapper">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
          :
          <div className='layout-wrapper layout-content-navbar'>
            <div className="layout-container">
              <SidebarComponent />
              <div className="layout-page">
                <UserHeaderComponent />
                <div className="content-wrapper">
                  <Outlet />
                </div>
                <FooterComponent />
              </div>
            </div>
          </div>

      }

    </>
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  },
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: '/register',
    element: <RegisterPage />
  },
  {
    path: '/register/:ucode',
    element: <RegisterPage />
  },
  {
    path: '/admin',
    element: <Layout />,
    children: [
      {
        path: 'dashboard',
        element: <DashboardPage />
      },
      {
        path: 'coin',
        element: <CoinPage />
      },
      {
        path: 'coin/:id',
        element: <CoinPage />
      },
      {
        path: 'uploaded-screenshots',
        element: <UploadedAttachmentsTable />
      },
      {
        path: 'settings',
        element: <SettingsPage />
      },
      {
        path: 'blog/category/add',
        element: <AddCategory />
      },
      {
        path: 'blog/tag/add',
        element: <AddTag />
      },
      {
        path: 'blog/tag/add/:id',
        element: <AddTag />
      },
      {
        path: 'blog/category/add/:id',
        element: <AddCategory />
      },
      {
        path: 'blog/add',
        element: <AddBlog />
      },
      {
        path: 'blog/add/:id',
        element: <AddBlog />
      },
      {
        path: 'blog',
        element: <BlogListPage />
      },
      {
        path: 'add-user',
        element: <UserForm />
      },
      {
        path: 'add-user/:id',
        element: <UserForm />
      },
      {
        path: 'import-users',
        element: <ImportUsers />
      },
      {
        path: 'fund-transfer',
        element: <FundPage />
      },
      {
        path: 'staking-reward',
        element: <StakingListComponent />
      },
      {
        path: 'plan',
        element: <PlanPage />
      },
      {
        path: 'plan/:id',
        element: <PlanPage />
      },
      {
        path: 'buy-history',
        element: <BuyHistoryPage />
      },
      {
        path: 'sell-history',
        element: <SellHistoryPage />
      },
      {
        path: 'user-list',
        element: <UserPage />
      },
      {
        path: 'users-roles/:id',
        element: <UserRolePage />
      },
      {
        path: 'roles-permission',
        element: <RolesPage />
      },
      {
        path: 'roles-permission/:id',
        element: <RolesPage />
      },
      {
        path: 'deposit',
        element: <Deposit />
      },
      {
        path: 'withdrawls',
        element: <Withdrawls />
      },
      {
        path: 'affiliate',
        element: <AffiliateRewardListComponent />
      },
      {
        path: 'royalty-rewards',
        element: <RoyaltyRewardListComponent />
      },
      {
        path: "home",
        element: ""
      },
      {
        path: 'profile',
        element: <ProfilePage />
      },
      {
        path: 'network',
        element: <Network />
      },

    ]
  },


  {
    path: '/user',
    element: <Layout />,
    children: [
      {
        path: 'dashboard',
        element: <UserDashboardPage />
      },
      {
        path: 'test/dashboard',
        element: <TestingDashboardPage />
      },
      {
        path: 'buyplan',
        element: <BuyPlanPage />
      },
      {
        path: 'profile',
        element: <ProfilePage />
      },
      {
        path: 'transaction',
        element: <TransactionPage />
      },
      {
        path: 'wallet',
        element: <WalletPage />
      },
      {
        path: 'staking-history',
        element: <StakingPage />
      },
      {
        path: 'support',
        element: <SupportPage />
      },
      {
        path: 'network',
        element: <Network />
      },
      {
        path: 'network/:code',
        element: <NetworkLevel />
      },
      {
        path: 'my-team',
        element: <MyTeam />
      },

      {
        path: 'direct-network',
        element: <DirectReferal />
      },

      {
        path: 'direct-network/:ucode',
        element: <DirectReferal />
      },


      {
        path: 'tree',
        element: <TreePage />
      },
      {
        path: 'fund/transfer',
        element: <FundTransferPage />
      }
    ]
  },

  {
    path: '/new-password',
    element: <NewPassword />
  },

  {
    path: '/verify-account',
    element: <VerifyAccount />
  },
  {
    path: '/reset-password',
    element: <ResetPassword />
  },
  {
    path: '/update-password',
    element: <ForgetPassword />
  },

  {
    path: '*', // Catch all unmatched routes
    element: <NotFoundPage />
  },

])
export default function App() {
  return (
    <RouterProvider router={router} />
  )
}
