import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify"; // Optional for notifications
import { useSelector } from "react-redux";
import { delete_plan, get_plan, get_status_plan } from "../../common/Api";
import { useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';

const PlanListComponent = () => {
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user);
  const [fund, setFund] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [selectedFund, setSelectedFund] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  

  const get_fund = async () => {
    try {
      const data = await get_plan(token);
      setFund(data.data);
      setFilteredCategory(data.data);
    } catch (error) {
      toast.error("Failed to fetch fund.");
    }
  };

  useEffect(() => {
    get_fund();
  }, [token]);

  const handleFundView = (fundData) => {
    setSelectedFund(fundData);
    setShow(true);
  };

  const deleteFund = async (ids) => {
    if (id == ids) {
      return toast.error("You can not edit or delete at same time");
    }
    const confirm = window.confirm("Are you sure to delete this plan ?");
    if (confirm) {
      try {
        const data = await delete_plan(token, ids);
        if (data.status == true) {
          toast.success(data.message);
          await get_fund();
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };
  const editFund = async (id) => {
    navigate(`/admin/plan/${id}`);
  };

  const handleStatus = async (id, status) => {
    try {
      let s = 0;
      if (status == 0) {
        s = 1;
      }
      const formdata = {
        stat: s,
      };
      const data = await get_status_plan(token, id, formdata);
      if (data.status == true) {
        toast.success(data.message);
        await get_fund();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => <>{row.amount}</>,
      sortable: true,
    },
    {
      name: "Max",
      selector: (row) => (
        <>
          {row.max} × ({row.amount * row.max})
        </>
      ),
      sortable: true,
    },
    {
      name: "Daily Percentage",
      selector: (row) => <>{row?.dailypercetage}</>,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <label className="switch">
            <input
              type="checkbox"
              className="switch-input"
              checked={row.status === 1}
              onChange={() => handleStatus(row._id, row.status)}
            />
            <span className="switch-toggle-slider">
              {row.status === 1 ? (
                <span className="switch-on">
                  <i className="ti ti-check"></i>
                </span>
              ) : (
                <span className="switch-off">
                  <i className="ti ti-x"></i>
                </span>
              )}
            </span>
          </label>
        </>
      ),
    },
    {
      name: "Created At",
      selector: (row) => new Date(row.creditDate).toLocaleDateString(), // Format date as needed
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <i
            className="fas fa-trash me-2"
            style={{ cursor: "pointer", color: "red" }}
            onClick={() => {
              Swal.fire({
                title: "Are you sure?",
                text: "This action will permanently delete the plan!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "Cancel",
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteFund(row._id);
                }
              });
            }}
          ></i>
          <i
            className="fas fa-edit me-2"
            style={{ cursor: "pointer", color: "green" }}
            onClick={() => editFund(row._id)}
          ></i>
        </>
      ),
    },
  ];

  return (
    <div className="col-lg-8">
      <div className="mb-3">
        <h4 className="card-header pb-4 text-white">Plan List</h4>
        <div className="card-body">
          {/* <div className="mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Email"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div> */}
          <DataTable
            columns={columns}
            data={filteredCategory}
            pagination
            highlightOnHover
            striped
          />
        </div>
      </div>
    </div>
  );
};

// Utility function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default PlanListComponent;
