import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  btuser_single_data_get,
  get_plan,
  user_plan_buy,
  get_user_wallet,
} from "../../../common/Api";
import { toast } from "react-toastify";

const TableViewBuyListComponent = () => {
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const { token, user } = useSelector((state) => state.user);
  const [fund, setFund] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [search, setSearch] = useState("");
  const [stake, setStake] = useState("");
  const [show, setShow] = useState(false);
  const [selectedFund, setSelectedFund] = useState({});
  const [users, setUsers] = useState({});
  const [availableAmount, setAvailableAmount] = useState(-1);
  const [amount, setAmount] = useState("");
  const [stakingAmount, setStakingAmount] = useState("");
  const [affiliateAmount, setAffiliateAmount] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setType("");
    setShow(false);
  };
  const handleShow = (row) => {
    setStake(row);
    setShow(true);
  };

  const formatAmount = (amount) => {
    if (amount < 0) return "0.00";
    return (Math.floor(amount * 100) / 100).toFixed(2);
  };

  const formateAmountCeil = (amount) =>{
    return Math.ceil(amount)
  }

  const selectHandleOptions = async (e) => {
    const wallet = document.querySelector("#wallet").value;
    setType(wallet);

    switch (parseInt(wallet)) {
      case 0:
        setAvailableAmount(stakingAmount);
        break;
      case 1:
        setAvailableAmount(affiliateAmount);
        break;
      case 2:
        setAvailableAmount(depositAmount);
        break;
      default:
        setAvailableAmount(0);
        break;
    }
  };

  const buyPlanHandle = async (id, amount) => {
    toast.dismiss();
    if (type === "") {
      return toast.error("Select at least one wallet");
    }

    if (amount < availableAmount) {
      const submitdata = {
        userId: user?._id,
        coinId: id,
        type: type,
      };
      console.log("buy plan handle ==> ", submitdata);

      // Set loading to true
      setIsLoading(true);

      const data = await user_plan_buy(submitdata, token);

      // Set loading to false after receiving response
      setIsLoading(false);

      if (data.status == true) {
        toast.success(data.message);
        setShow(false);
      } else {
        toast.success(data.response.data.message);
      }
    } else {
      toast.error("Insufficent Balance");
    }
  };

  const btGetSingleUserData = async () => {
    const data = await btuser_single_data_get(user._id, token);

    const __walletData = await get_user_wallet(user._id, token);

    setStakingAmount(__walletData?.data?.staking);
    setAffiliateAmount(__walletData?.data?.affility);
    setDepositAmount(__walletData?.data?.deposite);

    setUsers(data.data);
  };

  const get_fund = async () => {
    toast.dismiss();
    try {
      const data = await get_plan(token);
      setFund(data.data);
      setFilteredCategory(data.data);
    } catch (error) {
      toast.error("Failed to fetch fund.");
    }
  };

  useEffect(() => {
    get_fund();
    btGetSingleUserData();
  }, [token]);

  return (
    <>
      <h2 className="pb-3 pb-lg-3 pb-md-3 px-3 px-lg-5 px-md-5 text-white">
        Stake Plan Here
      </h2>

      <div className="container">
        <Table striped bordered-0 hover responsive className="align-middle">
          <thead className="text-nowrap">
            <tr>
              <th>#</th>
              <th>Plan</th>
              <th>Quantity</th>
              <th>Maximum Reward</th>
              <th>Daily Reward</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-nowrap">
            {filteredCategory?.length > 0 ? (
              <>
                {filteredCategory?.map((items, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{items?.name}</td>
                      <td>{items.amount} METABT</td>
                      <td>
                        {items?.planId?.name}
                        {items.max} × ({items.amount * items.max})
                      </td>
                      <td> {items?.dailypercetage} % METABT </td>
                      <td>
                        {" "}
                        <>
                          <button
                            className="ms-4 badge bg-label-success"
                            style={{ border: "none" }}
                            onClick={() => handleShow(items)}
                          >
                            Stake Plan
                          </button>
                        </>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                <p style={{ margin: "10px", textAlign: "center" }}>
                  There are no records to display
                </p>
              </>
            )}
          </tbody>
        </Table>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              Are you sure to stake this <b>"{stake?.name}"</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row table-resonsive">
              <div className="col-xl-12 col-md-12 col-sm-7 col-12 modal-tableData">
                <div className="table-responsive">
                  <table className="w-100 table table-striped table-hover">
                    <tbody className="text-nowrap">
                      <tr>
                        <td className="pe-4">Quantity</td>
                        <td className="fw-medium">{stake?.amount} METABT</td>
                      </tr>
                      <tr>
                        <td className="pe-4">Daily Amount</td>
                        <td>
                          {(stake?.amount * stake?.dailypercetage) / 100} METABT
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-4">Maximum Reward</td>
                        <td>{stake?.amount * stake?.max} METABT</td>
                      </tr>
                      <tr>
                        <td className="pe-4">Daily Reward</td>
                        <td>{stake?.dailypercetage} %</td>
                      </tr>
                      <tr>
                        <td className="pe-4">Select Wallet</td>
                        <td>
                          <select
                            className="wallet-select w-100"
                            value={type}
                            onChange={selectHandleOptions}
                            id="wallet"
                          >
                            <option value="">Select Wallet</option>
                            <option value="0">
                              Staking Wallet({formateAmountCeil(stakingAmount)}{" "}
                              METABT)
                            </option>
                            <option value="1">
                              Affiliate Wallet({formateAmountCeil(affiliateAmount)}{" "}
                              METABT)
                            </option>
                            <option value="2">
                              Deposit Wallet({formateAmountCeil(depositAmount)}{" "}
                              METABT)
                            </option>
                          </select>
                          {availableAmount >= 0 && (
                            <div>
                              Available Quantity:{" "}
                              {formatAmount(availableAmount)} METABT
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="success"
              onClick={() => buyPlanHandle(stake._id, stake.amount)}
              disabled={isLoading}
            >
              {isLoading ? "Processing..." : "Confirm"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default TableViewBuyListComponent;
