import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import './Network.css'
import { useSelector } from 'react-redux';
import { get_user_tree } from '../../../common/Api';
import btclogo from '../../assets/img/btclogo.png';

const rankMap = {
    1: 'Basic Club',
    2: 'Meta Founder Club',
    3: 'Meta Star Club',
    4: 'Meta Royal Club',
    5: 'Meta Prime Club',
    6: 'Meta Ambassador Club',
};



function Network() {
    const { user, token } = useSelector((state) => state.user);
    const [userLevels, setUserlevels] = useState([])
    const [nextlevel, setNextLevel] = useState([])

    const generalogy = async () => {
        try {
            const data = await get_user_tree(user._id, token)
            console.log(data)
            setUserlevels(data.secondLevelReferrals)
        } catch (error) {
            console.log(error)
        }
    }

    const handleTreeofUser = async (id) => {
        //alert(id)
        try {
            const data = await get_user_tree(id, token)
            console.log(data)
            setNextLevel(data.firstlevel)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        generalogy()
    }, [])


    const sliderRef = useRef(null);
    const zoomContainerRef = useRef(null);

    // States for zoom
    const [scale, setScale] = useState(1);
    const [isPinching, setIsPinching] = useState(false);
    const [initialDistance, setInitialDistance] = useState(0);

    // State for drag functionality
    const [isDown, setIsDown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    // Dragging functionality
    const handleMouseDown = (e) => {
        setIsDown(true);
        setStartX(e.pageX - sliderRef.current.offsetLeft);
        setScrollLeft(sliderRef.current.scrollLeft);
    };

    const handleMouseLeaveOrUp = () => setIsDown(false);

    const handleMouseMove = (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - sliderRef.current.offsetLeft;
        const walk = (x - startX) * 3; // Control scroll speed
        sliderRef.current.scrollLeft = scrollLeft - walk;
    };

    // Zoom in and out button handlers
    const zoomIn = () => setScale((prev) => prev + 0.1);
    const zoomOut = () => setScale((prev) => Math.max(0.1, prev - 0.1));

    const handleWheel = (event) => {
        event.preventDefault();
        setScale((prev) =>
            event.deltaY < 0 ? prev + 0.1 : Math.max(0.1, prev - 0.1)
        );
    };

    const getDistance = (touch1, touch2) => {
        const deltaX = touch2.clientX - touch1.clientX;
        const deltaY = touch2.clientY - touch1.clientY;
        return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    };

    const handleTouchStart = (event) => {
        if (event.touches.length === 2) {
            setIsPinching(true);
            setInitialDistance(getDistance(event.touches[0], event.touches[1]));
        }
    };

    const handleTouchMove = (event) => {
        if (isPinching && event.touches.length === 2) {
            const currentDistance = getDistance(event.touches[0], event.touches[1]);
            const scaleChange = currentDistance / initialDistance;
            setScale((prev) => Math.max(0.1, prev * scaleChange));
            setInitialDistance(currentDistance);
        }
    };

    const handleTouchEnd = () => setIsPinching(false);

    useEffect(() => {
        const zoomContainer = zoomContainerRef.current;
        zoomContainer.style.transform = `scale(${scale})`;
        zoomContainer.style.transformOrigin = 'top center';
    }, [scale]);


    return (
        <>
            <div className="tree px-4 py-4">
                <div className="card staking-reward mb-4 px-4 py-5 geneology_container" >
                    {/* <div className="card_header_area">
                        <form id="submit-tree" method="GET" className="d-flex align-items-center">
                            <h5 className="card-header ps-0">Unilevel Referral</h5>
                            <input type="text" name="ucode" className="form-control w-20 me-3" id="search-tree"
                                placeholder="Search users by ID" />
                            <button className="btn btn-primary waves-effect waves-light" type="submit">Search</button>
                        </form>
                    </div> */}
                    <div className="card-datatable table-responsive" >
                        <div className="content hv-container" >
                            <div className="hv-wrapper" >
                                <div className="hv-item m-0">
                                    <div className="table-responsive">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            {/* <div>
                                                <a href="#" className="btn btn-primary waves-effect waves-light">Principal Node</a>
                                            </div> */}
                                            {/* <div className="zoom-controls">
                                                <button id="zoom-in" onClick={zoomIn}>
                                                    Zoom In
                                                </button>
                                                <button id="zoom-out" onClick={zoomOut}>
                                                    Zoom Out
                                                </button>
                                            </div> */}
                                        </div>
                                        <div
                                            className="hv-item-children">
                                            <div
                                                className="zoom-container"
                                                ref={zoomContainerRef}
                                                onWheel={handleWheel}
                                                onTouchStart={handleTouchStart}
                                                onTouchMove={handleTouchMove}
                                                onTouchEnd={handleTouchEnd} >
                                                <div class="hv-item-parent">
                                                    <a class="info" href="#">
                                                        <img src={btclogo} border="0" align="middle" class="uimg" />
                                                        <div class="fs10"><span>{user?.btucode}</span></div>
                                                        <strong style={{ fontSize: "10px" }}>{user?.email}</strong>
                                                        <div class="fs10">Rank: <span>
                                                            {
                                                                rankMap[user?.btRank] || '-NA-'
                                                            }
                                                        </span></div>

                                                    </a>
                                                </div>
                                                <div class="hv-item-children">
                                                    {
                                                        userLevels.length > 0 ? <>
                                                            {
                                                                userLevels.map((items, i) => {
                                                                    return (
                                                                        <>
                                                                            <div class="hv-item-child">
                                                                                <div class="hv-item-parent 2">
                                                                                    <Link class="info" to={`/user/network/${items?.user.btucode}`}>
                                                                                        <img src={btclogo} border="0" align="middle" class="uimg" />
                                                                                        <div class="fs10"><span>{items?.user?.btucode}</span></div>
                                                                                        <strong>{items?.user?.email}</strong>
                                                                                        <div class="fs10">Rank: <span>{
                                                                                            rankMap[items?.user?.btRank] || '-NA-'
                                                                                        }</span></div>

                                                                                    </Link>
                                                                                </div>

                                                                                <div class="hv-item-children"  >
                                                                                {
                                                                                    items.secondLevelReferrals.length > 0 &&

                                                                                    <>
                                                                                        {
                                                                                            items.secondLevelReferrals.map((items, i) => {
                                                                                                return (
                                                                                                    
                                                                                                     <div class="hv-item-child 3" key={i}>
                                                                                                     <div class="hv-item-parent">
                                                                                                         <Link class="info" to={`/user/network/${items?.btucode}`}>
                                                                                                             <img src={btclogo} border="0" align="middle" class="uimg" />
                                                                                                             <div class="fs10"><span>{items?.btucode}</span></div>
                                                                                                             <strong>{items.email}</strong>
                                                                                                             <div class="fs10">Rank: <span>{
                                                                                                                 rankMap[items?.btRank] || '-NA-'
                                                                                                             }</span></div>

                                                                                                         </Link>
                                                                                                     </div>
                                                                                                 </div>

                                                                                                )
                                                                                            })
                                                                                        }



                                                                                    </>
                                                                                }
                                                                                                       </div>


                                                                               









                                                                            </div>

                                                                        </>
                                                                    )
                                                                })

                                                            }


                                                        </> : <>

                                                        </>
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Network
