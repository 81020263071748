import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify'; // Optional for notifications
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import axios from 'axios';
import { url } from '../Common/Url';
import { useSelector } from 'react-redux';
import { delete_coin_pair, get_coin_pair, get_coin_pair_status } from '../../common/Api';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const CoinListComponent = () => {
    const { token } = useSelector((state) => state.user)
    const [coin, setCoin] = useState([]);
    const [filteredCategory, setFilteredCategory] = useState([]);
    const [search, setSearch] = useState('');
    const [show, setShow] = useState(false);
    const [name, setName] = useState('')
    const [symbol, setSymbol] = useState('')
    const [previosValue, setPreviosValue] = useState(null)
    const [currentValue, setCurrentValue] = useState(null)
    const [volume, setVolume] = useState(null)
    const [coinPair, setCoinPair] = useState('')


    const handleClose = () => {
        setName('')
        setSymbol('')
        setPreviosValue(null)
        setCurrentValue(null)
        setVolume(null)
        setCoinPair('')
        setShow(false)
    };

    const handleShow = (name, symbol, currentValue, previosValue, coinPair, volume) => {
        setName(name)
        setSymbol(symbol)
        setPreviosValue(previosValue)
        setCurrentValue(currentValue)
        setVolume(volume)
        setCoinPair(coinPair)
        setShow(true)
    };



    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    const get_coin = async () => {
        try {
            const data = await get_coin_pair(token)
            console.log(data);
            setCoin(data.data);
            setFilteredCategory(data.data);
        } catch (error) {
            toast.error('Failed to fetch Coins.');
        }
    };

    useEffect(() => {
        get_coin();
    }, []);

    useEffect(() => {
        const result = coin.filter(coin =>
            coin.name.toLowerCase().includes(search.toLowerCase()) ||
            coin.symbol.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredCategory(result);
    }, [search, coin]);

    const handleStatus = async (id, s) => {
        try {
            let status = s === 1 ? 0 : 1;
            const formData = new FormData();
            formData.append('status', status);

            const data = await get_coin_pair_status(id, s, token)

            console.log(data);
            if (data.status == true) {
                toast.success(data.message)
                get_coin();
            }

        } catch (error) {
            toast.error(error.message);
        }
    };

    const handledelete = async (id) => {
        const confirm = window.confirm('Are you sure to delete this blog?');
        if (confirm) {
            try {
                const data = await delete_coin_pair(id, token)
                if (data.status == true) {
                    toast.success(data.message)
                    get_coin();
                }
            } catch (error) {
                toast.error(error.message);
            }
        }
    };

    const handleEdit = async (id) => {
        navigate(`/admin/coin/${id}`);
    };

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Symbol',
            selector: row => row.symbol,
            sortable: true,
        },
        {
            name: 'Current Price',
            selector: row => row.currentValue,
            sortable: true,
        },
        {
            name: 'Status',
            cell: row => (
                <>
                    <label className="switch">
                        <input type="checkbox" className="switch-input" checked={row.status === 1} onChange={() => handleStatus(row._id, row.status)} />
                        <span className="switch-toggle-slider">
                            {row.status === 1 ? (
                                <span className="switch-on">
                                    <i className="ti ti-check"></i>
                                </span>
                            ) : (
                                <span className="switch-off">
                                    <i className="ti ti-x"></i>
                                </span>
                            )}
                        </span>

                    </label>
                </>
            ),
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <i className="fas fa-edit me-2" style={{ cursor: "pointer", color: "green" }} onClick={() => handleEdit(row._id)}></i>
                    <i className="fas fa-trash me-2" style={{ cursor: "pointer", color: "red" }} onClick={() => handledelete(row._id)}></i>
                    <i className="fas fa-eye" style={{ cursor: "pointer", color: "blue" }} onClick={() => handleShow(row.name, row.symbol, row.currentValue, row.previosValue, row.coinPair, row.volume)}></i>
                </>
            ),
        },
    ];

    return (
        <div className='col-lg-8'>
            <div className="card mb-3">
                <h5 className="card-header">Coin List</h5>
                <div className="card-body">
                    <div className="mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by title , symbol...."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredCategory}
                        pagination
                        highlightOnHover
                        striped
                    />
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className='table border table-responsive'>
                        <tr className='border'>
                            <td className='border'>Coin Name</td>
                            <td className='border'>{name && name}</td>

                        </tr>
                        <tr className='border'>
                            <td className='border'>Symbol</td>
                            <td className='border'>{symbol && symbol}</td>

                        </tr>
                        <tr className='border'>
                            <td className='border'>Coin Pair</td>
                            <td className='border'>{coinPair && coinPair}</td>

                        </tr>
                        <tr className='border'>
                            <td className='border'>Previuos Price</td>
                            <td className='border'>$ {previosValue && previosValue}</td>

                        </tr>
                        <tr className='border'>
                            <td className='border'>Current Price </td>
                            <td className='border'>$ {currentValue && currentValue}</td>

                        </tr>
                        <tr className='border'>
                            <td className='border'>Volume </td>
                            <td className='border'> {volume && volume}</td>
                        </tr>
                    </table>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>

    );
};

export default CoinListComponent;
