import React from 'react'
import StakingComponent from '../../Component/Staking/StakingComponent'

export default function StakingPage() {
  return (
    <div>
      <StakingComponent />
    </div>
  )
}
