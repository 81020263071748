import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify'; // Optional for notifications
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import axios from 'axios';
import { url } from '../Common/Url';
import { useSelector } from 'react-redux';
import { delete_coin_pair, delete_roles, get_coin_pair, get_coin_pair_status, get_role, get_user, get_user_status } from '../../common/Api';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const RolesListComponent = () => {
    const { token } = useSelector((state) => state.user)
    const [roles, setRoles] = useState([]);
    const [filteredCategory, setFilteredCategory] = useState([]);
    const [search, setSearch] = useState('');




    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    const get_roles = async () => {
        try {
            const data = await get_role(token)
            console.log(data);
            setRoles(data.data);
            setFilteredCategory(data.data);
        } catch (error) {
            toast.error('Failed to fetch Roles.');
        }
    };

    useEffect(() => {
        get_roles();
    }, []);

    useEffect(() => {
        const result = roles.filter(user =>
            user.name.toLowerCase().includes(search.toLowerCase()) 
        );
        setFilteredCategory(result);
    }, [search, roles]);

    const handleStatus = async (id, s) => {
        try {
            let status = s === 1 ? 0 : 1;
            const formData = new FormData();
            formData.append('status', status);

            const data = await get_user_status(id, s, token)

            console.log(data);
            if (data.status == true) {
                toast.success(data.message)
                get_roles();
            }

        } catch (error) {
            toast.error(error.message);
        }
    };

    const handledelete = async (id) => {
        const confirm = window.confirm('Are you sure to delete this Roles?');
        if (confirm) {
            try {
                const data = await delete_roles(id, token)
                if (data.status == true) {
                    toast.success(data.message)
                    get_roles();
                }
            } catch (error) {
                toast.error(error.message);
            }
        }
    };

    const handleEdit = async (id) => {
        navigate(`/admin/roles-permission/${id}`);
    };

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Page Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Url',
            selector: row => row.url,
            sortable: true,
        },

        {
            name: 'Status',
            cell: row => (
                <>
                    <label className="switch">
                        <input type="checkbox" className="switch-input" checked={row.status === 1} onChange={() => handleStatus(row._id, row.status)} />
                        <span className="switch-toggle-slider">
                            {row.status === 1 ? (
                                <span className="switch-on">
                                    <i className="ti ti-check"></i>
                                </span>
                            ) : (
                                <span className="switch-off">
                                    <i className="ti ti-x"></i>
                                </span>
                            )}
                        </span>

                    </label>
                </>
            ),
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <i className="fas fa-edit me-2" style={{ cursor: "pointer", color: "green" }} onClick={() => handleEdit(row._id)}></i>
                    <i className="fas fa-trash me-2" style={{ cursor: "pointer", color: "red" }} onClick={() => handledelete(row._id)}></i>
                </>
            ),
        },
    ];

    return (
        <div className='col-lg-12'>
            <div className="staking-reward2 mb-3">
                <h4 className="card-header pb-4 text-white">Page & URL List</h4>
                <div className="card-body">
                    <div className="mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by title"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredCategory}
                        pagination
                        highlightOnHover
                        striped
                    />
                </div>
            </div>


        </div>

    );
};

export default RolesListComponent;
