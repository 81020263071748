import React from 'react'
import CoinForm from '../../Forms/Coin/CoinForm'
import CoinListComponent from '../../Components/Coin/CoinListComponent'

export default function CoinPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">

            <div className="row">
                <CoinForm />
                <CoinListComponent />
            </div>
        </div>
    )
}
