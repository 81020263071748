import React from 'react'
import BuyPlanForm from '../../Form/BuyPlan/BuyPlanForm'

export default function BuyPlanPage() {
  return (
    <div>
      <BuyPlanForm />
    </div>
  )
}
