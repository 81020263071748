import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../Services/UserSlices";
import { ToastContainer, toast } from "react-toastify";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import btlogo from "../../btclogo.png";
import Swal from "sweetalert2";

export default function LoginPage() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const { token, user } = useSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState(false);
  const [msg, setMsg] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // CAPTCHA state
  const [captcha, setCaptcha] = useState("");
  const [captchaAnswer, setCaptchaAnswer] = useState("");

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptcha(`${num1} + ${num2}`);
    setCaptchaAnswer(num1 + num2);
  };

  const initialValues = {
    email: "",
    password: "",
    captchaInput:""
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Please enter user email")
      .email("Invalid email format"),
    password: Yup.string().required("Please enter password"),
    captchaInput: Yup.string()
      .required("Please solve the CAPTCHA")
      .test("captcha-match", "CAPTCHA is incorrect", function (value) {
        return value == captchaAnswer; // Check if input matches answer
      }),
  });

  const handleLogin = async (formValue) => {
    const { email, password } = formValue;
    const data = { email, passwords: password };

    setLoading(true);
    toast.dismiss();
    try {
      const resultAction = await dispatch(getUser(data));
      console.log(resultAction);
      if (getUser.fulfilled.match(resultAction)) {
        console.log(resultAction);
        toast.success("Login Successful");
        setInterval(() => {
          if (resultAction?.payload?.data?.isAdmin === "user") {
            if(resultAction?.payload?.data?.firstlogin == 0){
              navigation("/new-password");
            }else{
              localStorage.setItem("refresh", "refresh");
              navigation("/user/dashboard");
            }
          } else {
            localStorage.setItem("refresh", "refresh");
            navigation("/admin/dashboard");
          }
        }, 2000);
      } else {
        return Swal.fire({
          icon: "error",
          title: "",
          text: resultAction.payload || "Login failed",
        });
        //setMsg(resultAction.payload || "Login failed");
        //toast.error(resultAction.payload || 'Login failed');
      }
    } catch (error) {
      toast.error("Failed to fetch the user");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" />
      <section class="login_form_sec">
        <div className="container-xxl">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner py-4">
              <div className="card bg-transparent">
                <div class="d-flex gap-2 logo justify-content-center pb-3">
                  <img
                    src={btlogo}
                    width="200px"
                    class="logo pt-1"
                  />
                  {/* <div class="logo-text text-white">
                    <h3 class="mb-0">BTCASH</h3>
                    <p>BUILD YOUR OWN COMMUNITY</p>
                  </div> */}
                </div>
                <div className="card-body staking-reward2 border-0 rounded-0">
                  <p className="fw-bold text-white">Login Your Account</p>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleLogin}
                  >
                    <Form className="mb-3 login-form">
                      <div className="mb-3">
                        <label className="form-label">Email ID *</label>
                        <Field
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="Enter your email"
                        />
                        <ErrorMessage
                          name="email"
                          component="span"
                          className="errorInput"
                        />
                      </div>
                      <div className="mb-3 form-password-toggle">
                        <div className="d-flex justify-content-between">
                          <label className="form-label">Password *</label>
                        </div>
                        <div className="input-group input-group-merge">
                          <Field
                            name="password"
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                          />
                          <span
                            className="input-group-text cursor-pointer"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? (
                              <i className="ti ti-eye"></i>
                            ) : (
                              <i className="ti ti-eye-off"></i>
                            )}
                          </span>
                        </div>
                        <ErrorMessage
                          name="password"
                          component="span"
                          className="errorInput"
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          Solve CAPTCHA: <span style={{ background:"linear-gradient(rgb(54, 47, 116), rgb(57, 34, 88))", color:"white", padding:"2px 4px"}}>{captcha} </span>
                        </label>
                        <Field
                          name="captchaInput"
                          type="text"
                          className="form-control"
                          placeholder="Enter the result"
                        />
                        <ErrorMessage
                          name="captchaInput"
                          component="span"
                          className="errorInput"
                        />
                      </div>

                      <div className="mb-2 pt-1 text-center ">
                        <button
                          type="submit"
                          className="border-0 rounded d-grid w-100 loginBtn d-flex gap-1 align-items-center justify-content-center"
                          disabled={loading}
                        >
                          {loading && (
                            <span className="spinner-border spinner-border-sm fw-bold text-center"></span>
                          )}
                          <span>Login</span>
                        </button>
                      </div>
                      {msg && (
                        <p style={{ color: "red", textAlign: "center" }}>
                          {msg}
                        </p>
                      )}
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="form-check mb-0 d-flex align-items-center">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="remember-me"
                          />
                          <label className="form-check-label mb-0 ms-2 links-login">
                            {" "}
                            Remember Me{" "}
                          </label>
                        </div>
                        <div className="form-check">
                          <Link
                            to="/reset-password"
                            className="mb-0 text-white links-login"
                          >
                            Forgot Password ?
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
                <div class="register text-center pt-3">
                  <p>
                    Don't have an account?{" "}
                    <span>
                      {" "}
                      <a
                        href="/register"
                        className="fw-bold"
                        style={{ color: "#ACA4EB" }}
                      >
                        Register
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
