import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { importUsers, updateReferralId } from "../common/Api";

const ImportUsers = () => {
  const [users, setUsers] = useState([]);
  const { token } = useSelector((state) => state.user);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const fileExtension = file.name.split(".").pop();

    if (fileExtension === "xlsx" || fileExtension === "xls") {
      readExcel(file);
    } else if (fileExtension === "csv") {
      readCSV(file);
    } else {
      alert("Unsupported file format");
    }
  };

  // Function to handle Excel file
  const readExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setUsers(jsonData);
      uploadInBatches(jsonData, 1000); // Upload in batches of 1000
    };
    reader.readAsArrayBuffer(file);
  };

  // Function to handle CSV file with streaming
  const readCSV = (file) => {
    Papa.parse(file, {
      header: true,
      chunk: (result) => {
        setUsers((prevUsers) => [...prevUsers, ...result.data]);
        uploadInBatches(result.data, 1000); // Upload in batches of 1000
      },
      complete: () => {
        console.log("CSV file processed.");
      },
    });
  };

  // Function to upload parsed data to the server in batches
  const uploadInBatches = (data, batchSize) => {
    setUploading(true);
    const totalBatches = Math.ceil(data.length / batchSize);
    let batchIndex = 0;

    const uploadBatch = async () => {
      if (batchIndex >= totalBatches) {
        setUploading(false);
        setUploadProgress(100);
        alert("All data uploaded successfully!");
        return;
      }

      const batchData = data.slice(
        batchIndex * batchSize,
        (batchIndex + 1) * batchSize
      );
      try {
        await updateReferralId(batchData,token); // Call your custom importUsers function
        const progress = Math.round(((batchIndex + 1) / totalBatches) * 100);
        setUploadProgress(progress);
        batchIndex++;
        uploadBatch(); // Recursively upload the next batch
      } catch (error) {
        console.error("Error uploading batch", error);
        alert("Error uploading batch");
        setUploading(false);
      }
    };

    uploadBatch(); // Start the batch upload
  };

  return (
    <div className="col-lg-12">
      <div className="card mb-3">
        <h5 className="card-header">Import Users from Excel or CSV</h5>
        <div className="card-body">
          <div>
            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              onChange={handleFileUpload}
            />
            {uploading && (
              <div>
                <p>Uploading: {uploadProgress}%</p>
                <progress value={uploadProgress} max="100" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportUsers;
