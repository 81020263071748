import React, { useEffect, useState } from 'react';
import { daily_reward_update, get_user_dashboard_stat } from '../../../common/Api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import UserProfile from './UserProfile';
import LineChart from './Char';
import TwoLineChart from './TwoLineChart';
import BarChart from './BarChart';
import mapImg from './map.png'
import { useNavigate } from 'react-router-dom';

export default function UserDashboardPage() {
  const navigate = useNavigate()
  const { user, token } = useSelector((state) => state.user);
  const refesh = localStorage.getItem('refresh')
  const [todaystake, setTodaSTake] = useState(0);
  const [todayaffiliate, setTodayAffiliate] = useState(0);
  const [totalStake, setTotalStake] = useState(0);
  const [totalaffiliate, setTotalAffiliate] = useState(0);

  const [todaystakedata, setTodayStakeData] = useState([]);
  const [todayaffilaiteData, setTodayAffilaiteData] = useState([]);
  const [totalStakingdata, setTotalStakingData] = useState([]);
  const [totalaffiliatedata, setTotalAffiliateData] = useState([]);
  const [totalDeposit, setTotalDeposit] = useState("");
  const [totalWithdrawal, setTotalWithdrawal] = useState("");

  const upto_date_reward = async () => {
    try {
      const dailyupdate = await daily_reward_update(user._id, token);
      if (dailyupdate.status) {
        toast.success('Daily reward Updated');
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const user_dashboard = async () => {
    try {
      const response = await get_user_dashboard_stat(user._id, token);
      console.log('user total response ==> ', response);

      // Use slicing to limit data to the latest 7 entries
      setTodaSTake(response?.tstake?.totalAmount);
      setTodayAffiliate(response?.tstake?.todayAffiliateAmount);
      setTotalAffiliate(response?.tstake?.totalAffiliateAmount);
      setTotalStake(response?.tstake?.totalStakingAmount);

      // Slice the data arrays to get only the latest 7 entries
      setTodayStakeData(response?.tstake?.todayData?.slice(0, 7) || []);
      setTodayAffilaiteData(response?.tstake?.todayAffiliateData?.slice(0, 7) || []);
      setTotalStakingData(response?.tstake?.totalStakingData?.slice(0, 7) || []);
      setTotalAffiliateData(response?.tstake?.totalAffiliateData?.slice(0, 7) || []);
      setTotalDeposit(response?.totalDeposit);
      setTotalWithdrawal(response?.totalWithdrawal);

    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    //console.log("Component mounted or updated");
    if (user._id && token) {
      if (refesh) {
        localStorage.removeItem('refresh')
        window.location.reload();
      }
      // upto_date_reward();
      user_dashboard();
    } else {
      navigate('/')
    }

  }, [user._id, token]);

  // Function to format numbers to two decimal places
  const formatAmount = (amount) => {
    return parseFloat(amount).toFixed(2);
  };

  console.log(refesh)

  return (
    <section className="dashboard">
      <div className="container-fluid px-3 px-lg-4 px-md-4">
        <div className="row flex-lg-row flex-column-reverse dashboard_item">
          <div className="col-lg-9 pt-0 pt-lg-5 pt-md-5 pb-5">
            <div className="text-left">
              <h3>Welcome to Dashboard</h3>
              {/* <p>Hi, {user?.username}</p> */}
            </div>
            <div className="row gy-2 gx-2">
              <div className="col-lg-6 col-md-6 col-12 h-100">
                <div className="row gy-2 gx-2">
                  <div className="col-6">
                    <div className="staking-reward h-100">
                      <div className="staking-reward_logo d-flex align-items-center">
                        <i className="fa-solid fa-layer-group"></i>
                        <p>TODAY STAKING REWARD</p>
                      </div>
                      <div className="chart-container">
                        <div className="value-display">{formatAmount(todaystake)} <span style={{ fontSize: "13px" }}>METABT</span></div>
                        {/* <div className="meta-display">METABT</div> */}
                        <LineChart datas={todaystakedata} color='rgb(8, 217, 154)' />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="staking-reward h-100">
                      <div className="staking-reward_logo d-flex align-items-center">
                        <i className="fa-solid fa-bullhorn"></i>
                        <p>TODAY AFFILIATE REWARD</p>
                      </div>
                      <div className="chart-container">
                        <div className="value-display">{formatAmount(todayaffiliate)} <span style={{ fontSize: "13px" }}>METABT</span></div>
                        {/* <div className="meta-display">METABT</div> */}
                        <LineChart datas={todayaffilaiteData} color='rgb(237, 27, 3)' />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="staking-reward h-100">
                      <div className="staking-reward_logo d-flex align-items-center">
                        <i className="fa-solid fa-scale-balanced"></i>
                        <p>TOTAL STAKE REWARD</p>
                      </div>
                      <div className="chart-container">
                        <div className="value-display">{formatAmount(totalStake)} <span style={{ fontSize: "13px" }}>METABT</span></div>
                        {/* <div className="meta-display">METABT</div> */}
                        <LineChart datas={totalStakingdata} color='rgb(222, 98, 140)' />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="staking-reward h-100">
                      <div className="staking-reward_logo d-flex align-items-center">
                        <i className="fa-solid fa-layer-group"></i>
                        <p>TOTAL AFFILIATE REWARD</p>
                      </div>
                      <div className="chart-container">
                        <div className="value-display">{formatAmount(totalaffiliate)} <span style={{ fontSize: "13px" }}>METABT</span></div>
                        {/* <div className="meta-display">METABT</div> */}
                        <LineChart datas={totalaffiliatedata} color='rgb(200, 98, 222)' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="staking-reward2 h-100">
                  <div className="chart-container2">
                    <div className="d-flex justify-content-between">
                      <p>Staking / Affiliate</p>
                    </div>
                    <TwoLineChart datas1={totalStakingdata} datas2={totalaffiliatedata} color='rgb(200, 98, 222)' />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="staking-reward2 h-100">
                  <div className="chart-container2">
                    <div className="d-flex justify-content-between">
                      <p>Staking / Affiliate</p>
                    </div>
                    <BarChart datas1={totalStakingdata} datas2={totalaffiliatedata} />
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className="card staking-reward">
                  <div class="map-container">
                    <img src={mapImg} className='img-fluid' />
                    <div class="point venezuela tippy" title="Venezuela"></div>
                    <div class="point brasil tippy" title="Brasil"></div>
                    <div class="point argentina tippy" title="Argentina"></div>
                    <div class="point colombia tippy" title="Colombia"></div>
                    <div class="point panama tippy" title="Panamá"></div>
                    <div class="point mexico tippy" title="Mexico"></div>
                    <div class="point usa tippy" title="Estados Unidos"></div>
                    <div class="point arabia tippy" title="Arabia Saudi"></div>
                    <div class="point turquia tippy" title="Turquía"></div>
                    <div class="point rusia tippy" title="Rusia"></div>
                    <div class="point china tippy" title="China"></div>
                    <div class="point japon tippy" title="Japon"></div>
                    <div class="point australia tippy" title="Australia"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <UserProfile user={user} totalDeposit={totalDeposit} totalWithdrawal={totalWithdrawal} />
          </div>
        </div>
      </div>
    </section>
  );
}
