import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { url } from '../../../Components/Common/Url';
import DataTable from 'react-data-table-component';

const ReferralTable = ({ userId }) => {
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        const fetchTeamStructure = async () => {
            try {
                const response = await axios.get(`${url}/api/v1/user/my-team/${userId}`);
                //console.
                const flattenedData = flattenTree(response.data.data);
                console.log('flattenedData',response.data.data)
                setTableData(flattenedData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTeamStructure();
    }, [userId]);

    const flattenTree = (nodes, level = 1) => {
        let flatData = [];
        for (const node of nodes) {
            flatData.push({
                username: node.user.username,
                joiningdate: node.user.createdAt,
                referal: node.user.btreferal,
                email: node.user.email,
                rank:node.user.btRank,
                level: level,
            });
            if (node.user.referrals && node.user.referrals.length > 0) {
                flatData = flatData.concat(flattenTree(node.user.referrals, level + 1));
            }
        }
        return flatData;
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    console.log(tableData)


    const rankCritica = [
        { id: 1, name: "Basic Club" },
        { id: 2, name: "Meta Founder Club" },
        { id: 3, name: "Meta Star Club" },
        { id: 4, name: "Meta Royal Club" },
        { id: 5, name: "Meta Prime Club" },
        { id: 6, name: "Meta Ambassador Club" },
      ];
    
      // Function to get rank name based on ID
      const getRankName = (rankId) => {
        const rank = rankCritica.find((r) => r.id === rankId);
        return rank ? rank.name : "-NA-"; // Return rank name or '-NA-' if not found
      };


     const columnss = [
    {
      name: "#",
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
      width: "50px",
      center: true, // Optional: centers the column content
    },
    {
      name: "Username",
      selector: (row) => row.username || "-NA-", // Using the (row) => format
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email || "-NA-", // Using the (row) => format
      sortable: true,
    },


    {
      name: "Sponser",
      selector: (row) => row.referal || "-NA-", // Using the (row) => format
      sortable: true,
    },
    {
      name: "Level",
      selector: (row) => row.level || "-NA-", // Using the (row) => format
      sortable: true,
    },
    {
      name: "Rank",
      selector: (row) => getRankName(row.rank) || "-NA-", // Using the (row) => format
      sortable: true,
    },
    // {
    //   name: "No of Staking",
    //   selector: (row) => row.user?.noOfStaking || 0, // Defaulting to 0 if undefined
    //   sortable: true,
    // },
    // {
    //   name: "Total Staking",
    //   selector: (row) => formatAmount(row.user?.totalStaking) || 0.0, // Defaulting to 0 if undefined
    //   sortable: true,
    // },
    {
      name: "Joining Date",
      selector: (row) => {
        const date = new Date(row.joiningdate);
        return date.toLocaleDateString() || "-NA-"; // Handling null or undefined values
      },
      sortable: true,
    },
  ];

    return (
        <div>
            {/* <h2>Team Structure</h2>
            <table>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Level</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((row, index) => (
                        <tr key={index}>
                            <td>{row.email}</td>
                            <td>{row.level}</td>
                        </tr>
                    ))}
                </tbody>
            </table> */}
            <DataTable
                columns={columnss}
                data={tableData} // Pass the fetched team data here
                pagination
                highlightOnHover
                striped
                paginationDefaultPage={currentPage}
                onChangePage={(page) => setCurrentPage(page)}
              />
        </div>
    );
};

export default ReferralTable;
