import React from "react";
import { Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import moment from "moment";

export default function FundTransferListComponent({ data }) {
  const getWalletDetails = (type) => {
    switch (type) {
      case 1:
        return `Staking Wallet`;
      case 2:
        return `Affiliate Wallet`;
      case 4:
        return `Royalty Wallet`;
      default:
        return "-NA-";
    }
  };

  return (
    <div>
      <h4>Transfer List</h4>
      <Table striped bordered-0 hover responsive>
        <thead className="text-nowrap">
          <tr>
            <th>#</th>
            <th>Email</th>
            <th>Wallet</th>
            <th>Amount</th>
            <th>Date</th>
            {/* <th>Remarks</th> */}
          </tr>
        </thead>
        <tbody className="text-nowrap">
          {data.length > 0 ? (
            <>
              {data.map((items, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{items?.email}</td>
                    <td>{getWalletDetails(items?.walletType)}</td>
                    <td>{items?.amount} METABT</td>
                    <td>
                      {moment(items?.createdAt).format("DD-MM-YYYY h:mm:ss a")}
                    </td>
                    {/* <td>{items?.remarks}</td> */}
                  </tr>
                );
              })}
            </>
          ) : (
            <>
              <p>No Record Found!</p>
            </>
          )}
        </tbody>
      </Table>
    </div>
  );
}
