import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { get_user_tree } from '../../common/Api';

export default function TreeListComponent() {
    const { user, token } = useSelector((state) => state.user);
    const [userLevels, setUserlevels] = useState([])
    const [nextlevel, setNextLevel] = useState([])

    const generalogy = async () => {
        try {
            const data = await get_user_tree(user._id, token)
            console.log(data)
            setUserlevels(data.firstlevel)
        } catch (error) {
            console.log(error)
        }
    }

    const handleTreeofUser = async (id) => {
        //alert(id)
        try {
            const data = await get_user_tree(id, token)
            console.log(data)
            setNextLevel(data.firstlevel)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        generalogy()
    }, [])

    console.log(user)

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div class="body genealogy-body genealogy-scroll">
                            <div class="genealogy-tree">
                                <ul>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <div class="member-view-box">
                                                <div class="member-image" style={{ textAlign: "center" }}>
                                                    <img src="https://img.freepik.com/premium-vector/anonymous-user-circle-icon-vector-illustration-flat-style-with-long-shadow_520826-1931.jpg?w=360" alt="Member" />
                                                    <div class="member-details">
                                                        <h3 style={{ fontSize: "12px" }}>{user?.email}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        {
                                            userLevels.length > 0 ?
                                                <>
                                                    <ul class="active">
                                                        {
                                                            userLevels.map((items, i) => {
                                                                return (

                                                                    <li>
                                                                        <a href="javascript:void(0);" onClick={() => handleTreeofUser(items._id)}>
                                                                            <div class="member-view-box">
                                                                                <div class="member-image">
                                                                                    <img src="https://img.freepik.com/premium-vector/anonymous-user-circle-icon-vector-illustration-flat-style-with-long-shadow_520826-1931.jpg?w=360" alt="Member" />
                                                                                    <div class="member-details">
                                                                                        <h3 style={{ fontSize: "12px" }}>{items?.email}</h3>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        {
                                                                            nextlevel.length > 0 ? <>
                                                                                <ul class="active">

                                                                                    {
                                                                                        nextlevel.map((itemss, i) => {
                                                                                            return (

                                                                                                <li>
                                                                                                    <a href="javascript:void(0);" >
                                                                                                        <div class="member-view-box">
                                                                                                            <div class="member-image">
                                                                                                                <img src="https://img.freepik.com/premium-vector/anonymous-user-circle-icon-vector-illustration-flat-style-with-long-shadow_520826-1931.jpg?w=360" alt="Member" />
                                                                                                                <div class="member-details">
                                                                                                                    <h3 style={{ fontSize: "12px" }}>{itemss?.email}</h3>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </a>

                                                                                                </li>
                                                                                            )
                                                                                        })

                                                                                    }

                                                                                </ul>
                                                                            </> : <>

                                                                            </>
                                                                        }
                                                                    </li>



                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </>

                                                : ''
                                        }

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
