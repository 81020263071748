import React from 'react'
import SellListHistoryComponent from '../../Components/Coin/SellListHistoryComponent'

export default function SellHistoryPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">

            <div className="row">
                <SellListHistoryComponent />
            </div>
        </div>
    )
}
