import React, { useEffect, useState } from "react";
import { get_admin_transaction_record } from "../../common/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DataTableViewTransaction from "../../BtCash/Component/Transaction/DataTableViewTransaction";

function Deposit() {
  const { user, token } = useSelector((state) => state.user);
  const [data, setData] = useState([]);

  const get_record = async (type, value) => {
    try {
      const response = await get_admin_transaction_record(user._id, token, type, value);
      setData(response);
    } catch (error) {
      toast.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    get_record('deposit', 2);
  }, []);

  return (
    <>
      <div className="container-xxl flex-grow-1">
        <div className="mb-3 mt-3">
          
            <DataTableViewTransaction data={data} title='Deposit History'/>
          
        </div>
      </div>
    </>
  );
}

export default Deposit;
