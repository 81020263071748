import React, { useState } from 'react'
import { Container, Row } from 'react-bootstrap'

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { verify_user_account } from '../../common/Api';
import { toast, ToastContainer } from 'react-toastify';



const VerifyAccount = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();
  const getemail = query.get('email');
  const gettoken = query.get('token');

  //console.log(gettoken)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirm, setConfirmPassword] = useState('')

  const hanldeUpdatePassword = async () => {
    try {
      const submitData = {
        email: getemail,
        token: gettoken,
      }
      const data = await verify_user_account(submitData)
      if (data.status == true) {
        toast.success(data.message)
        setInterval(() => {
          navigate('/')
        }, 2000)
      }

    } catch (error) {

    }
  }


  return (
    <>
      <ToastContainer position='top-center'/>
      <div className="container">
        <div className='accountVerify'>
          <div className="row w-100">
            <div className="col-lg-6 offset-lg-3">
              <div className="staking-reward2 card css-v8v0jn e1pc040f1">
                <div className="card-body py-5">
                  <h1 className="css-1ffs9d1 e131aulr0">
                    <span dir="auto" className='verfiyText'>Account Varification</span>
                  </h1>
                  <p dir="auto" style={{color: "#d5d5d5"}}>
                    To proceed with varify your account
                  </p>
                  <button
                    className="btn waves-effect waves-light mt-2"
                    data-test-id="login-submit-button"
                    onClick={hanldeUpdatePassword}
                    style={{ backgroundColor: "#28c76f70", borderColor: "#28c76f00"}}
                  >
                    <span dir="auto" className='text-white'>Varify Account</span>
                  </button>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default VerifyAccount;
