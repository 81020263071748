import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { fetch_my_team, fetch_my_direct_referrals } from "../../../common/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import ReferralTable from "./ReferalTeam";

export default function MyTeam() {
  const { user, token } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [rdata, setrData] = useState([]);
  const [activeTab, setActiveTab] = useState("direct_referrals"); // Default active tab
  const [currentPage, setCurrentPage] = useState(1);
  const [teamStructure, setTeamStructure] = useState([]);
  const itemsPerPage = 10; // Set items per page

  useEffect(() => {
    fetchMyTeam();
    fetchMyDirect();
  }, [activeTab, user, token]);

  const fetchMyDirect = async () => {
    try {
      const results = await fetch_my_direct_referrals(user._id, token);
      const teamData = results.data;
      console.log("team data ==> ", teamData);
      if (Array.isArray(teamData)) {
        setrData(teamData); // Set data if it's an array
      } else {
        console.error("Expected an array but received:", teamData);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error fetching team data");
    }
  };

  const fetchMyTeam = async () => {
    try {
      const results = await fetch_my_team(user._id, token);
      const teamData = results.data;
      console.log("team data ==> ", teamData);
      if (Array.isArray(teamData)) {
        setData(teamData); // Set data if it's an array
        setTeamStructure(teamData)
      } else {
        console.error("Expected an array but received:", teamData);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error fetching team data");
    }
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  const handleSelect = (key) => {
    setActiveTab(key);
    // Optionally, you can refetch the data when the tab changes
    // fetchMyTeam();
  };

  const rankCritica = [
    { id: 1, name: "Basic Club" },
    { id: 2, name: "Meta Founder Club" },
    { id: 3, name: "Meta Star Club" },
    { id: 4, name: "Meta Royal Club" },
    { id: 5, name: "Meta Prime Club" },
    { id: 6, name: "Meta Ambassador Club" },
  ];

  // Function to get rank name based on ID
  const getRankName = (rankId) => {
    const rank = rankCritica.find((r) => r.id === rankId);
    return rank ? rank.name : "-NA-"; // Return rank name or '-NA-' if not found
  };

  // Define the columns for the DataTable
  const columns = [
    {
      name: "#",
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
      width: "50px",
      center: true, // Optional: centers the column content
    },
    {
      name: "Username",
      selector: (row) => row.username || "-NA-", // Using the (row) => format
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email || "-NA-", // Using the (row) => format
      sortable: true,
    },


    {
      name: "Sponser",
      selector: (row) => row.sponserId || "-NA-", // Using the (row) => format
      sortable: true,
    },
    {
      name: "Level",
      selector: (row) => row.level || "-NA-", // Using the (row) => format
      sortable: true,
    },
    {
      name: "Rank",
      selector: (row) => getRankName(row.rank) || "-NA-", // Using the (row) => format
      sortable: true,
    },
    {
      name: "No of Staking",
      selector: (row) => row.noOfStaking || 0, // Defaulting to 0 if undefined
      sortable: true,
    },
    {
      name: "Total Staking",
      selector: (row) => formatAmount(row.totalStaking) || 0.0, // Defaulting to 0 if undefined
      sortable: true,
    },
    {
      name: "Joining Date",
      selector: (row) => {
        const date = new Date(row.joiningDate);
        return date.toLocaleDateString() || "-NA-"; // Handling null or undefined values
      },
      sortable: true,
    },
  ];


  // const columnss = [
  //   {
  //     name: "#",
  //     selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
  //     width: "50px",
  //     center: true, // Optional: centers the column content
  //   },
  //   {
  //     name: "Username",
  //     selector: (row) => row.user?.username || "-NA-", // Using the (row) => format
  //     sortable: true,
  //   },
  //   {
  //     name: "Email",
  //     selector: (row) => row.user?.email || "-NA-", // Using the (row) => format
  //     sortable: true,
  //   },


  //   {
  //     name: "Sponser",
  //     selector: (row) => row.user?.btreferal || "-NA-", // Using the (row) => format
  //     sortable: true,
  //   },
  //   {
  //     name: "Level",
  //     selector: (row) => row.user?.btLevel || "-NA-", // Using the (row) => format
  //     sortable: true,
  //   },
  //   {
  //     name: "Rank",
  //     selector: (row) => getRankName(row.user?.btRank) || "-NA-", // Using the (row) => format
  //     sortable: true,
  //   },
  //   {
  //     name: "No of Staking",
  //     selector: (row) => row.user?.noOfStaking || 0, // Defaulting to 0 if undefined
  //     sortable: true,
  //   },
  //   // {
  //   //   name: "Total Staking",
  //   //   selector: (row) => formatAmount(row.user?.totalStaking) || 0.0, // Defaulting to 0 if undefined
  //   //   sortable: true,
  //   // },
  //   {
  //     name: "Joining Date",
  //     selector: (row) => {
  //       const date = new Date(row.user?.createdAt);
  //       return date.toLocaleDateString() || "-NA-"; // Handling null or undefined values
  //     },
  //     sortable: true,
  //   },
  // ];


  const flattenTree = (nodes, level = 1) => {
    let flatData = [];
    for (const node of nodes) {
      flatData.push({
        email: node.user.email,
        level: level,
      });
      if (node.user.referrals && node.user.referrals.length > 0) {
        flatData = flatData.concat(flattenTree(node.user.referrals, level + 1));
      }
    }
    return flatData;
  };



  return (
    <>
      <div>
        <div className="container-xxl flex-grow-1 container-p-y">
          <Tabs
            activeKey={activeTab}
            onSelect={handleSelect}
            id="fill-tab-example"
            className="mb-3 tab-transaction"
          >
            <Tab
              eventKey="direct_referrals"
              title="My Team"
              className="tab-his"
            >
              {/* DataTable for Direct Referrals */}
              {/* <DataTable
                columns={columnss}
                data={data} // Pass the fetched team data here
                pagination
                highlightOnHover
                striped
                paginationDefaultPage={currentPage}
                onChangePage={(page) => setCurrentPage(page)}
              /> */}


              <ReferralTable userId={user._id} />
            </Tab>
            <Tab eventKey="my_team" title="Direct Referrals">
              <DataTable
                columns={columns}
                data={rdata} // Pass the fetched team data here
                pagination
                highlightOnHover
                striped
                paginationDefaultPage={currentPage}
                onChangePage={(page) => setCurrentPage(page)}
              />
              {/* You can add another DataTable or content for "My Team" tab here */}
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}
