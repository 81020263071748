import React from 'react'
import RolesForm from '../../Forms/Roles/RolesForm'
import RolesListComponent from '../../Components/Role/RolesListComponent'

export default function RolesPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-lg-4">
                    <RolesForm />
                </div>
                <div className="col-lg-8">
                  <RolesListComponent />
                </div>
            </div>
        </div>
    )
}
