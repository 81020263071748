
import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { add_plan, update_plan } from '../../common/Api';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { url } from '../../Components/Common/Url';


const PlanForm = () => {
    const params = useParams();
    const { id } = params;
    const { token } = useSelector((state) => state.user);

    const [name, setName] = useState('')
    const [amount, setAmount] = useState(null)
    const [max, setMax] = useState('')
    const [daily, setDaily] = useState('')

    const handleSettingPage = async (e) => {

        e.preventDefault();


        if (name == '') {
            return toast.error('Please Enter The name of the plan')
        }

        const submitData = {

            name: name,
            amount: amount,
            max: max,
            dailypercetage: daily

        }
        try {
            const data = await add_plan(submitData, token)
            console.log(data)
            if (data.status == true) {
                toast.success(data.message)
                setInterval(() => {
                    window.location.reload();
                }, 2000)
            } else {
                toast.error(data.response.data.message)
            }

        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    const handleUpdatePlan = async(e) =>{
        e.preventDefault();
        const submitData = {

            name: name,
            amount: amount,
            max: max,
            dailypercetage: daily

        }
        try {
            const data = await update_plan(submitData, id , token)
            console.log(data)
            if (data.status == true) {
                toast.success(data.message)
                setInterval(() => {
                    window.location.reload();
                }, 2000)
            } else {
                toast.error(data.response.data.message)
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }

    useEffect(() => {
        if (id) {
          const getStepData = async () => {
            const { data } = await axios.get(`${url}/api/v2/bt/plan/${id}`, {
              headers: {
                token: `${token}`,
              },
            });
            setName(data.data.name);
            setAmount(data.data.amount);
            setMax(data.data.max);
            setDaily(data.data.dailypercetage);
            
          };
          getStepData();
        } else {
            setName('');
            setAmount('');
            setMax('');
            setDaily('');
        }
      }, [id]);


    return (
        <div className="col-lg-4">
            <div className="staking-reward2 mb-3">
                <ToastContainer />
                <div className="card-header pt-2">
                    <ul className="nav nav-tabs card-header-tabs" role="tablist">
                        <li className="nav-item mb-2" role="presentation">
                            <button
                                className='nav-link active'

                            >
                                PLan
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="tab-content">
                    <div className="tab-pane fade active show card-body" role="tabpanel">


                        <div className="row g-3">

                            <div className="col-md-12">
                                <label className="form-label">
                                    Plan Name
                                </label>
                                <div className="same">
                                    <input
                                        type='text'
                                        className="form-control"
                                        name='email'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}

                                    />

                                </div>

                            </div>


                            <div className="col-md-12">
                                <label className="form-label">
                                    Amount
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}

                                />
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">
                                    Max Reward Like : 1 ,2 ,3 ,4 ,5
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={max}
                                    onChange={(e) => setMax(e.target.value)}

                                />
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">
                                    Daily Percentage
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={daily}
                                    onChange={(e) => setDaily(e.target.value)}

                                />
                            </div>


                        </div>


                        <div className="pt-4">
                            <button type="submit" className="border-0 rounded loginBtn addUserBTn me-sm-3 me-1 waves-effect" onClick={id ? handleUpdatePlan : handleSettingPage}>
                                Add Plan
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlanForm;
