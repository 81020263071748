import React from 'react'
import AddTagForm from '../../../Forms/Blog/Category/AddTagForm'
import TagListComponent from '../../../Components/Blog/Category/TagListComponent'

export default function AddTag() {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col-lg-4">
          <AddTagForm />
        </div>
        <div className='col-lg-8'>
          <TagListComponent />
        </div>

      </div>
    </div>
  )
}
