import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import {
  add_new_coin,
  add_new_user,
  get_single_coin_pair,
  get_single_user,
  update_coin_pair,
  update_new_user,
} from "../../common/Api";
import { useParams, useNavigate } from "react-router-dom";

const UserForm = () => {
  const { token } = useSelector((state) => state.user);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(null);
  const [password, setPassword] = useState(null);
  const [role, setRole] = useState(null);
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;

  console.log("dsdsd ==>", id);

  const handleSettingPage = async (e) => {
    e.preventDefault();

    if (username == "") {
      return toast.error("Please Enter The name of user");
    }
    if (email == "") {
      return toast.error("Please Enter The email of user");
    }
    const roles = document.querySelector("#role").value;
    const submitData = {
      username: username,
      email: email,
      numnber: phone,
      password: password,
      isAdmin: roles,
    };
    try {
      const data = await add_new_user(submitData, token);
      console.log(data);
      if (data.status == true) {
        toast.success(data.message);
        setInterval(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(data.response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const get_single_users = async () => {
    try {
      const data = await get_single_user(id, token);
      console.log(data);
      setUsername(data.data.username);
      setEmail(data.data.email);
      setPhone(data.data.numnber);
      setRole(data.data.isAdmin);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (id) {
      get_single_users();
    } else {
      setUsername("");
      setEmail("");
      setPhone("");
      setRole("");
    }
  }, [id]);

  const handleUpdateCoin = async (e) => {
    e.preventDefault();
    const roles = document.querySelector("#role").value;
    const submitData = {
      username: username,
      email: email,
      numnber: phone,
      isAdmin: roles,
    };
    try {
      const data = await update_new_user(id, submitData, token);

      if (data.status == true) {
        toast.success(data.message);
        setInterval(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(data.response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col-lg-6 col-md-8">
          <div className="staking-reward2 mb-3">
            <ToastContainer />
            <div className="card-header pt-2">
              <ul className="nav nav-tabs card-header-tabs" role="tablist">
                <li className="nav-item mb-2" role="presentation">
                  <button className="nav-link active ">
                    {id ? "Edit User" : "Add User"}
                  </button>
                </li>
              </ul>
            </div>

            <div className="tab-content">
              <div
                className="tab-pane fade active show card-body"
                role="tabpanel"
              >
                {id && (
                  <>
                    <p className="mb-4">
                      {" "}
                      Edit User <b>"{username}"</b>
                    </p>
                  </>
                )}
                <form onSubmit={id ? handleUpdateCoin : handleSettingPage}>
                  <div className="row g-3">
                    <div className="col-md-12">
                      <label className="form-label">Username</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="symbol"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    {id ? (
                      <> </>
                    ) : (
                      <>
                        <div className="col-md-12">
                          <label className="form-label">Password</label>
                          <input
                            type="password"
                            className="form-control"
                            name="symbol"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </>
                    )}

                    <div className="col-md-12">
                      <label className="form-label">Phone</label>
                      <input
                        type="number"
                        className="form-control"
                        name="symbol"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">Role</label>
                      <select className="addUser form-control" id="role">
                        <option value="user">Select Role</option>
                        {role === "sub-admin" ? (
                          <>
                            <option value="sub-admin" selected>
                              Sub Admin
                            </option>
                            <option value="user">User</option>
                          </>
                        ) : (
                          <>
                            <option value="sub-admin">Sub Admin</option>
                            <option value="user" selected>
                              User
                            </option>
                          </>
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="pt-4">
                    <button
                      type="submit"
                      className="border-0 rounded me-sm-3 me-1 waves-effect loginBtn addUserBTn"
                    >
                      {id ? `Update User ${username}` : "Add User"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
