import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify'; // Optional for notifications
import { Link, useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import { useSelector } from 'react-redux';
import { delete_user, get_buy_history, update_user_status } from '../../common/Api';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';


const BuyListComponent = () => {
    const { token } = useSelector((state) => state.user)
    const [buy, setBuy] = useState([]);
    const [filteredCategory, setFilteredCategory] = useState([]);
    const [search, setSearch] = useState('');
    const [show, setShow] = useState(false);
    const [totalBuyValue, setTotalBuyValue] = useState(0);
    const [totalpendingBuyValue, setTotalPendingBuyValue] = useState(0);
    const [totalsuccessBuyValue, setTotalSuccessBuyValue] = useState(0);
    const [total, setTotal] = useState(0);




    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    const get_users = async () => {
        try {
            const data = await get_buy_history(token)
            console.log(data);
            setBuy(data.data);
            setFilteredCategory(data.data);
            setTotalBuyValue(data.totalBuyValue)
            setTotalPendingBuyValue(data.totalPendingbuyValue)
            setTotalSuccessBuyValue(data.totalSeccussbuyValue)
            setTotal(data.totalbuyStats)
        } catch (error) {
            console.log(error)
            toast.error('Failed to fetch Coins.');
        }
    };

    useEffect(() => {
        get_users();
    }, []);

    useEffect(() => {
        const result = buy.filter(user =>
            user.userId.username.toLowerCase().includes(search.toLowerCase()) ||
            user.userId.email.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredCategory(result);
    }, [search, buy]);



    const handledelete = async (id) => {
        const confirm = window.confirm('Are you sure to delete this Buy History?');
        if (confirm) {
            try {
                const data = await delete_user(id, token)
                if (data.status == true) {
                    toast.success(data.message)
                    get_users();
                }
            } catch (error) {
                toast.error(error.message);
            }
        }
    };

    const handleEdit = async (id) => {
        navigate(`/admin/edituser/${id}`);
    };

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
        },

        {
            name: 'Username',
            selector: row => row?.userId?.username,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row?.userId?.email,
            sortable: true,
        },
        {
            name: 'Coin',
            selector: row => row?.coinId?.symbol,
            sortable: true,
        },

        {
            name: 'Qunatity',
            selector: row => row.volume,
            sortable: true,
        },
        {
            name: 'Price',
            selector: row => <>
                <Badge bg="success">
                    $ {row.buyPrice}
                </Badge>
            </>,
            sortable: true,
        },
        {
            name: 'Total Value',
            selector: row => <>$ {row.totalValue}</>,
            sortable: true,
        },

        {
            name: 'Status',
            cell: row => (
                <>
                    {row.status === 1 ? (
                        <Badge bg="success">
                            Done
                        </Badge>
                    ) : (
                        <Badge bg="warning">
                            Pending
                        </Badge>
                    )}
                </>
            ),
        },
        {
            name: 'Actions',
            cell: row => (
                <>

                    <i className="fas fa-trash me-2" style={{ cursor: "pointer", color: "red" }} onClick={() => handledelete(row._id)}></i>
                </>
            ),
        },
    ];

    return (
        <>
            <div className='col-lg-12 mb-3'>
                <div className="card">
                    <div class="card-body">
                        <div class="row gy-3">
                            <div class="col-md-3 col-6">

                                <div class="d-flex align-items-center">
                                    <div class="badge rounded-pill bg-label-info me-3 p-2">
                                        <i class="ti ti-users ti-sm"></i>
                                    </div>
                                    <div class="card-info">
                                        <h5 class="mb-0">$ {total && total}</h5>
                                        <small>Total Buy Count</small>
                                    </div>
                                </div>



                            </div>
                            <div class="col-md-3 col-6">

                                <div class="d-flex align-items-center">
                                    <div class="badge rounded-pill bg-label-danger me-3 p-2">
                                        <i class="ti ti-users ti-sm"></i>
                                    </div>
                                    <div class="card-info">
                                        <h5 class="mb-0">$ {totalBuyValue && totalBuyValue}</h5>
                                        <small>Total Buy Value</small>
                                    </div>
                                </div>



                            </div>

                            <div class="col-md-3 col-6">

                                <div class="d-flex align-items-center">
                                    <div class="badge rounded-pill bg-label-warning me-3 p-2">
                                        <i class="ti ti-users ti-sm"></i>
                                    </div>
                                    <div class="card-info">
                                        <h5 class="mb-0">$ {totalpendingBuyValue && totalpendingBuyValue}</h5>
                                        <small>Total Pending Buy Value</small>
                                    </div>
                                </div>



                            </div>


                            <div class="col-md-3 col-6">

                                <div class="d-flex align-items-center">
                                    <div class="badge rounded-pill bg-label-success me-3 p-2">
                                        <i class="ti ti-users ti-sm"></i>
                                    </div>
                                    <div class="card-info">
                                        <h5 class="mb-0">$ {totalsuccessBuyValue && totalsuccessBuyValue}</h5>
                                        <small>Total Success Buy Value</small>
                                    </div>
                                </div>



                            </div>



                        </div>
                    </div>
                </div>
            </div>

            <div className='col-lg-12'>
                <div className="card mb-3">
                    <h5 className="card-header">Buy History </h5>
                    <div className="card-body">
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search by name , email "
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        <DataTable
                            columns={columns}
                            data={filteredCategory}
                            pagination
                            highlightOnHover
                            striped
                        />
                    </div>
                </div>


            </div>
        </>
    );
};

export default BuyListComponent;
