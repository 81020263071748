import React from "react";
import { useSelector } from "react-redux";
import BuyListComponent from "../../Component/BuyPlan/BuyPlanListComponent";
import UserProfile from "../../Page/Dashboard/UserProfile";
import TableViewBuyListComponent from "../../Component/BuyPlan/TableViewBuyListComponent";

export default function BuyPlanForm() {
  const { user } = useSelector((state) => state.user);

  return (
    <div className="me-3">
      <div className="row">
        <div className="col-lg-9 col-md-6 col-12 pt-3">
          {/* <BuyListComponent /> */}
          <TableViewBuyListComponent />
        </div>
        <div className="col-lg-3 col-md-6 col-12 px-4 pt-5 pt-lg-0 pt-md-0 d-none d-lg-block d-md-block">
          <UserProfile />
        </div>
      </div>
    </div>
  );
}
