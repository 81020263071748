import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { add_fund_user, find_fund_user } from "../../common/Api";

const FundForm = () => {
  const { token } = useSelector((state) => state.user);

  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(null);
  const [remarks, setRemarks] = useState("");

  const handleSettingPage = async (e) => {
    e.preventDefault();

    if (email == "") {
      return toast.error("Please Enter The email of user");
    }
    const type = document.querySelector("#type").value;
    const wallet = document.querySelector("#wallet").value;
    const submitData = {
      email: email,
      amount: amount,
      type: type,
      walletType: wallet,
      remarks: remarks,
    };
    try {
      const data = await add_fund_user(submitData, token);
      console.log(data);
      if (data.status == true) {
        toast.success(data.message);
        setInterval(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(data.response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleEmailVerify = async () => {
    try {
      const submitData = {
        email: email,
      };
      const data = await find_fund_user(submitData, token);
      console.log(data);
      if (data.status === true) {
        toast.success(`User Found ${data?.data?.email}`);
      } else {
        toast.error("User not found!");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="col-lg-4">
      <div className="staking-reward2 mb-3">
        <ToastContainer />
        <div className="card-header pt-2">
          <ul className="nav nav-tabs card-header-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active">Fund</button>
            </li>
          </ul>
        </div>

                <div className="tab-content">
                    <div className="tab-pane fade active show card-body" role="tabpanel">


                        <div className="row g-3">

                            <div className="col-md-12">
                                <label className="form-label">
                                    Email
                                </label>
                                <div className="same d-flex">
                                    <input
                                        type='email'
                                        className="form-control"
                                        name='email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        style={{borderRadius: '0.5rem  0 0 0.5rem'}}

                                    />
                                    <button className='border-0 rounded px-3 py-1 waves-effect loginBtn addUserBTn text-center' style={{borderRadius: '0 0.5rem  0.5rem 0'}} onClick={handleEmailVerify}>Verify</button>
                                </div>

                            </div>


              <div className="col-md-12">
                <label className="form-label">Amount</label>
                <input
                  type="number"
                  className="form-control"
                  name="symbol"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>

              <div className="col-md-12">
                <label className="form-label">Type</label>
                <select className="addUser form-control" id="type">
                  <option value="deduct">Select type</option>
                  <option value="add">Add</option>
                  <option value="deduct">Deduct</option>
                </select>
              </div>

              <div className="col-md-12">
                <label className="form-label">Wallet</label>
                <select
                  className="form-control"
                  aria-label="Default select example"
                  id="wallet"
                >
                  <option value="">Select Wallet</option>
                  <option value="1">
                    Staking Wallet
                  </option>
                  <option value="2">
                    Affiliate Wallet
                  </option>
                  <option value="3">
                    Deposit Wallet
                  </option>
                </select>
              </div>
              <div className="col-md-12">
                <label className="form-label">Remarks</label>
                <textarea
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  className="textareaUser form-control"
                ></textarea>
              </div>
            </div>

            <div className="pt-4">
              <button
                type="submit"
                className="border-0 rounded loginBtn addUserBTn me-sm-3 me-1 waves-effect"
                onClick={handleSettingPage}
              >
                Fund Transfer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundForm;
