import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../Services/UserSlices';
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import btlogo from '../../btclogo.png'
import { add_user, update_user_forget_mail } from '../../common/Api';




export default function ForgetPassword() {

    const location = useLocation();
    const navigate = useNavigate()
    const useQuery = () => {
        return new URLSearchParams(location.search);
    };
    const query = useQuery();
    const getemail = query.get('email');
    const gettoken = query.get('token');


    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [loading, setLoading] = useState(false);

    const { token, user } = useSelector((state) => state.user);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirm, setConfirmPassword] = useState('')



    const hanldeUpdatePassword = async () => {
        try {
            if (password == '' || setConfirmPassword == '') {
                return toast.error('Please Enter Password');
            }

            if (password != confirm) {
                return toast.error('Password and Confirm Password does not match!');
            }


            const submitData = {
                email: getemail,
                token: gettoken,
                password: password
            }
            const data = await update_user_forget_mail(submitData)
            if (data.status == true) {
                toast.success(data.message)
                setInterval(() => {
                    navigate('/')
                }, 2000)
            }

        } catch (error) {

        }
    }



    return (
        <>
            <ToastContainer position="top-center" />
            <section class="login_form_sec">
                <div className="container-xxl">
                    <div className="authentication-wrapper authentication-basic container-p-y">
                        <div className="authentication-inner py-4">
                            <div className='card p-4'>
                                <h1 className="css-1ffs9d1 e131aulr0">
                                    <span dir="auto">Password recovery</span>
                                </h1>
                                <div className="css-v8v0jn e1pc040f1 mb-2">
                                    <span dir="auto ">
                                        To proceed with changing your password
                                    </span>
                                </div>

                                <div className="css-1u76601 e1pc040f3">
                                    <div className="css-8atqhb efcvym30">
                                        <div className="css-1b7kpa egzimz0 mb-4">
                                            <input
                                                autoComplete="new-password"
                                                step="any"
                                                placeholder="Password"
                                                name="identifier"
                                                type="password"
                                                className="form-control css-kvxi0i egzimz1"
                                                defaultValue=""
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />

                                        </div>
                                        <div className="css-1b7kpa egzimz0">
                                            <input
                                                autoComplete="new-password"
                                                step="any"
                                                placeholder="Confirm Password"
                                                name="identifier"
                                                type="password"
                                                className="form-control css-kvxi0i egzimz1"
                                                defaultValue=""
                                                value={confirm}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <button
                                    className="btn btn-success waves-effect waves-light mb-2 mt-2"
                                    data-test-id="login-submit-button"
                                    onClick={hanldeUpdatePassword}
                                >
                                    <span dir="auto">Update Password</span>
                                </button>

                                <div className="css-xi606m e3v9ozq0">
                                    <Link to="/" >Back to Log In</Link>
                                    <div data-test-id="auth-link" className="css-gecade e3v9ozq3">
                                        <span dir="auto" className='fs14'>
                                            Don't have an account?{" "}
                                            <Link to="/sign-up" className="link">
                                                Sign Up
                                            </Link>
                                        </span>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}
