import React from 'react'
import BuyListComponent from '../../Components/Coin/BuyListComponent'

export default function BuyHistoryPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">

            <div className="row">
             <BuyListComponent />
            </div>
        </div>
    )
}
