import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { add_new_coin, get_single_coin_pair, update_coin_pair } from '../../common/Api';
import { useParams } from 'react-router-dom';

const CoinForm = () => {
    const { token } = useSelector((state) => state.user);
    const [name, setName] = useState('')
    const [symbol, setSymbol] = useState('')
    const [previosValue, setPreviosValue] = useState(null)
    const [currentValue, setCurrentValue] = useState(null)
    const [volume, setVolume] = useState(null)
    const [coinPair, setCoinPair] = useState('')

    const params = useParams()
    const { id } = params;

    console.log(id)

    const handleSettingPage = async (e) => {

        e.preventDefault();

        if (name == '') {
            return toast.error('Please Enter The name of coin')
        }
        const submitData = {
            name: name,
            symbol: symbol,
            previosValue: previosValue,
            currentValue: currentValue,
            coinPair: coinPair,
            volume: volume


        }
        try {
            const data = await add_new_coin(submitData, token)

            if (data.status == true) {
                toast.success(data.message)
                setInterval(() => {
                    window.location.reload();
                }, 2000)
            } else {
                toast.error(data.response.data.message)
            }

        } catch (error) {
            toast.error(error.message);
        }
    };

    const get_single_coin = async () => {
        try {
            const data = await get_single_coin_pair(id, token)
            console.log(data)
            setName(data.data.name)
            setSymbol(data.data.symbol)
            setPreviosValue(data.data.previosValue)
            setCurrentValue(data.data.currentValue)
            setVolume(data.data.volume)
            setCoinPair(data.data.coinPair)
        } catch (error) {
            toast.error(error.message);
        }
    }


    useEffect(() => {
        if (id) {
            get_single_coin()
        } else {
            setName('')
            setSymbol('')
            setPreviosValue('')
            setCurrentValue('')
            setVolume('')
            setCoinPair('')
        }

    }, [id])

  const handleUpdateCoin = async(e) =>{
    e.preventDefault();
    const submitData = {
        name: name,
        symbol: symbol,
        previosValue: previosValue,
        currentValue: currentValue,
        coinPair: coinPair,
        volume: volume


    }
    try {
        const data = await update_coin_pair(submitData, id , token)

        if (data.status == true) {
            toast.success(data.message)
            setInterval(() => {
                window.location.reload();
            }, 2000)
        } else {
            toast.error(data.response.data.message)
        }

    } catch (error) {
        toast.error(error.message);
    }
  }



    return (
        <div className="col-lg-4">
            <div className="card mb-3">
                <ToastContainer />
                <div className="card-header pt-2">
                    <ul className="nav nav-tabs card-header-tabs" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className='nav-link active'

                            >
                                COIN
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="tab-content">
                    <div className="tab-pane fade active show card-body" role="tabpanel">
                        {
                            id && <>
                                Edit Coin <b>"{name}"</b>
                            </>
                        }
                        <form onSubmit={id ? handleUpdateCoin : handleSettingPage}>
                            <div className="row g-3">
                                <div className="col-md-12">
                                    <label className="form-label">
                                        Name
                                    </label>
                                    <input
                                        type='text'
                                        className="form-control"
                                        name='name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}

                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">
                                        Symbol
                                    </label>
                                    <input
                                        type='text'
                                        className="form-control"
                                        name='symbol'
                                        value={symbol}
                                        onChange={(e) => setSymbol(e.target.value)}

                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">
                                        Previous value
                                    </label>
                                    <input
                                        type='number'
                                        className="form-control"
                                        name='symbol'
                                        value={previosValue}
                                        onChange={(e) => setPreviosValue(e.target.value)}

                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">
                                        Current value
                                    </label>
                                    <input
                                        type='number'
                                        className="form-control"
                                        name='symbol'
                                        value={currentValue}
                                        onChange={(e) => setCurrentValue(e.target.value)}

                                    />
                                </div>

                                <div className="col-md-12">
                                    <label className="form-label">
                                        Volume
                                    </label>
                                    <input
                                        type='number'
                                        className="form-control"
                                        name='symbol'
                                        value={volume}
                                        onChange={(e) => setVolume(e.target.value)}

                                    />
                                </div>

                                <div className="col-md-12">
                                    <label className="form-label">
                                        Coin Pair
                                    </label>
                                    <input
                                        type='text'
                                        className="form-control"
                                        name='symbol'
                                        value={coinPair}
                                        onChange={(e) => setCoinPair(e.target.value)}

                                    />
                                </div>

                            </div>


                            <div className="pt-4">
                                <button type="submit" className="btn btn-primary me-sm-3 me-1 waves-effect waves-light">
                                    {id ? `Update Coin ${name}`: 'Add Coin' }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoinForm;
