import React from 'react'
import ProfileForm from '../../Form/User/ProfileForm'

export default function ProfilePage() {
  return (
    <div>
      <ProfileForm />
    </div>
  )
}
