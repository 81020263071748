import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify"; // Optional for notifications
import { useSelector } from "react-redux";
import { get_fund_history } from "../../common/Api";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const FundHistoryListComponent = () => {
  const { token } = useSelector((state) => state.user);
  const [fund, setFund] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [selectedFund, setSelectedFund] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const get_fund = async () => {
    try {
      const data = await get_fund_history(token);
      setFund(data.data);
      setFilteredCategory(data.data);
    } catch (error) {
      toast.error("Failed to fetch fund.");
    }
  };

  useEffect(() => {
    get_fund();
  }, [token]);

  useEffect(() => {
    const result = fund.filter((user) =>
      user.email.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredCategory(result);
  }, [search, fund]);

  const handleFundView = (fundData) => {
    setSelectedFund(fundData);
    setShow(true);
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => (
        <>
          {row.amount}
          <i
            className={`menu-icon tf-icons ti ${
                (row.type === "add" || row.type === "1"  || row.type === "2" || row.type === "3") ? "ti-arrow-up" : "ti-arrow-down"
            }`}
            style={{
              fontSize: "13px",
              fontWeight: "bold",
              color: (row.type === "add" || row.type === "1"  || row.type === "2" || row.type === "3") ? "#28c76f" : "#ff9f43",
            }}
          ></i>
        </>
      ),
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => {
        if (row.type === "add") {
          return <Badge bg="success">Add</Badge>;
        } else if (row.type === "deduct") {
          return <Badge bg="warning">Deduct</Badge>;
        } else {
          return <Badge bg="success">Add</Badge>;
        }
      },
      sortable: true,
    },
    {
      name: "Wallet",
      selector: (row) => {
        if (row.walletType === 1) {
          return <Badge bg="success">Staking Wallet</Badge>;
        } else if (row.walletType === 2) {
          return <Badge bg="info">Affiliate Wallet</Badge>;
        } else if (row.walletType === 3) {
          return <Badge bg="warning">Deposit Wallet</Badge>;
        } else{
          return '-NA-';
        }
      },
      sortable: true,
    },
    {
      name: "Credit Date",
      selector: (row) => new Date(row.creditDate).toLocaleDateString(), // Format date as needed
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <i
          className="fas fa-eye me-2"
          style={{ cursor: "pointer", color: "green" }}
          onClick={() => handleFundView(row)}
        ></i>
      ),
    },
  ];

  return (
    <div className="col-lg-8">
      <div className="mb-3">
        <h4 className="card-header pb-4 text-white">Fund List</h4>
        <div className="card-body">
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Email"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredCategory}
            pagination
            highlightOnHover
            striped
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Fund History {selectedFund.email}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
          <table className="table ">
            <tbody className="text-nowrap">
              {Object.entries(selectedFund).map(([key, value]) =>
                key !== "amount" && key !== "type" ? (
                  <tr key={key}>
                    <td>
                      <b>{capitalizeFirstLetter(key)}</b>
                    </td>
                    <td>{value}</td>
                  </tr>
                ) : null
              )}
              <tr>
                <td>
                  <b>Amount</b>
                </td>
                <td>
                  {selectedFund.amount}
                  <i
                    className={`menu-icon tf-icons ti ${
                      selectedFund.type === "add"
                        ? "ti-arrow-up"
                        : "ti-arrow-down"
                    }`}
                    style={{
                      fontSize: "13px",
                      fontWeight: "bold",
                      color:
                        selectedFund.type === "add" ? "#28c76f" : "#ff9f43",
                    }}
                  ></i>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Type</b>
                </td>
                <td>
                  <Badge
                    bg={selectedFund.type === "add" ? "success" : "warning"}
                  >
                    {selectedFund.type}
                  </Badge>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <Button variant="secondary" onClick={handleClose} style={{background : '#e4141696'}} className="border-0">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

// Utility function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default FundHistoryListComponent;
