import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useSelector } from "react-redux";
import axios from "axios";
import { url, Image } from "../../../Components/Common/Url";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddBlogForm = () => {
  const params = useParams();
  const { id } = params;

  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [metakeywords, setMetakeywords] = useState("");
  const [slug, setSlug] = useState("");
  const [category, setCategory] = useState([]);
  const [tag, setTag] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedTag, setSelectedTags] = useState([]);

  const navigate = useNavigate();

  const handleDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.type.startsWith("image/")) {
      setImage(file);
    } else {
      toast.error("Invalid file type. Please upload an image file.");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/*"],
    onDrop: handleDrop,
  });

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleBlog = async () => {
    if (!title) {
      return toast.error("Please Enter Title");
    }

    try {
      const { data } = await axios.post(
        `${url}/api/v1/blog/add`,
        {
          lan: activeLanguage,
          title: title,
          image: image,
          description: description,
          metatitle: metatitle,
          metadescription: metadescription,
          metakeywords: metakeywords,
          slug: slug,
          cat: selectedCategory,
          tag: selectedTag,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status === true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (id) {
      const getStepData = async () => {
        const { data } = await axios.get(`${url}/api/v1/blog/get/${id}`, {
          headers: {
            token: `${token}`,
          },
        });
        if (activeLanguage === "ar") {
          setDescription(data.data.ar_description);
          setTitle(data.data.ar_title);
          setImage(data.data.ar_image);
          setMetatitle(data.data.ar_metatitle);
          setMetakeywords(data.data.ar_metakeywords);
          setMetadescription(data.data.ar_metadescription);
          setSlug(data.data.ar_slug);
          setSelectedCategory(data.data.ar_category);
          setSelectedTags(data.data.ar_tag);
        } else {
          setDescription(data.data.en_description);
          setTitle(data.data.en_title);
          setImage(data.data.en_image);
          setMetatitle(data.data.en_metatitle);
          setMetakeywords(data.data.en_metakeywords);
          setMetadescription(data.data.en_metadescription);
          setSlug(data.data.en_slug);
          setSelectedCategory(data.data.en_category);
          setSelectedTags(data.data.en_tag);
        }
      };
      getStepData();
    } else {
      setDescription("");
      setTitle("");
      setImage("");
      setMetatitle("");
      setMetakeywords("");
      setMetadescription("");
      setSlug("");
      setSelectedCategory([]);
      setSelectedTags([]);
    }
  }, [id, activeLanguage]);

  const handleStepUpdate = async () => {
    try {
      const { data } = await axios.put(
        `${url}/api/v1/blog/update/${id}`,
        {
          lan: activeLanguage,
          title: title,
          image: image,
          description: description,
          metatitle: metatitle,
          metadescription: metadescription,
          metakeywords: metakeywords,
          slug: slug,
          cat: selectedCategory,
          tag: selectedTag,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status === true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
    let stringWithoutSpaces = e.target.value.replace(/\s+/g, "-");
    setSlug(stringWithoutSpaces.toLowerCase());
  };

  const handleCategoryChange = (categoryId) => {
    const isSelected = selectedCategory.includes(categoryId);
    if (isSelected) {
      setSelectedCategory(selectedCategory.filter((id) => id !== categoryId));
    } else {
      setSelectedCategory([...selectedCategory, categoryId]);
    }
  };

  const handleTagChange = (tagId) => {
    const isSelected = selectedTag.includes(tagId);
    if (isSelected) {
      setSelectedTags(selectedTag.filter((id) => id !== tagId));
    } else {
      setSelectedTags([...selectedTag, tagId]);
    }
  };

  const getCategory = async () => {
    const { data } = await axios.get(`${url}/api/v1/blog/category/get/`, {
      headers: {
        token: `${token}`,
      },
    });
    setCategory(data.data);
  };
  const getTag = async () => {
    const { data } = await axios.get(`${url}/api/v1/blog/tag/get/`, {
      headers: {
        token: `${token}`,
      },
    });
    setTag(data.data);
  };

  useEffect(() => {
    getCategory();
    getTag();
  }, []);

  console.log(selectedCategory);

  return (
    <>
      <div className="col-lg-8">
        <div className="card mb-3">
          <ToastContainer />

          <div className="card-header pt-2">
            <h4>{id ? `Edit Blog ${title}` : "Add Blog"}</h4>
            <ul className="nav nav-tabs card-header-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeLanguage === "en" ? "active" : ""
                  }`}
                  onClick={() => changeLanguage("en")}
                >
                  English
                </button>
              </li>
              
            </ul>
          </div>

          <div className="tab-content">
            <div className="tab-pane fade active show" role="tabpanel">
              <div>
                <div className="row g-3">
                  <div className="col-md-12">
                    <label className="form-label">{t("Title")}</label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={title}
                      onChange={handleTitle}
                    />
                  </div>

                  <div className="col-md-12">
                    <label className="form-label">{t("Description")}</label>
                    <CKEditor
                      key={`editor1-${activeLanguage}`}
                      config={{
                        language: {
                          ui: activeLanguage === "ar" ? "ar" : "en", // UI language
                          content: activeLanguage === "ar" ? "ar" : "en", // Content language
                        },
                        contentsLangDirection:
                          activeLanguage === "ar" ? "rtl" : "ltr", // Content direction
                      }}
                      editor={ClassicEditor}
                      data={description}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                      }}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">{t("Meta Title")}</label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={metatitle}
                      onChange={(e) => setMetatitle(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">
                      {t("Meta Description")}
                    </label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={metadescription}
                      onChange={(e) => setMetadescription(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">{t("Meta Keywords")}</label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={metakeywords}
                      onChange={(e) => setMetakeywords(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">{t("slug")}</label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={slug}
                      onChange={(e) => setSlug(e.target.value)}
                    />
                  </div>
                </div>
                <div className="pt-4">
                  {id ? (
                    <button
                      type="submit"
                      className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                      onClick={handleStepUpdate}
                    >
                      {t("Update")}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                      onClick={handleBlog}
                    >
                      {t("Submit")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card mb-3">
          <div className="card-body">
            <div className="row g-3">
              <div className="col-md-12 border p-3">
                <label className="form-label">{t("All Categories")}</label>
                <ul
                  className="cats-list"
                  style={{ listStyle: "none", padding: "0px" }}
                >
                  {category.map((item) => (
                    <li key={category._id} className="me-2">
                      <input
                        className="me-2"
                        type="checkbox"
                        id={item._id}
                        value={item._id}
                        checked={selectedCategory.includes(item._id)}
                        onChange={() => handleCategoryChange(item._id)}
                      />
                      <label htmlFor={item._id}>
                        {activeLanguage === "ar"
                          ? item.ar_title
                          : item.en_title}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-12">
                <label className="form-label">{t("Image")}</label>

                <div {...getRootProps()} style={dropzoneStyles}>
                  <input {...getInputProps()} />
                  <p>
                    {t(
                      "Drag and drop an image file here, or click to select one"
                    )}
                  </p>
                </div>
                {image && image.path}
                <div className="" style={{ textAlign: "center" , marginTop:"15px" }}>
                  {image && (
                    <img src={`${Image}/${image}`} width="200px" alt="Blog" />
                  )}
                </div>
              </div>
              <div className="col-md-12 border p-3">
                <label className="form-label">{t("All Tags")}</label>
                <ul
                  className="cats-list"
                  style={{ listStyle: "none", padding: "0px" }}
                >
                  {tag.map((item) => (
                    <li key={item._id} className="me-2">
                      <input
                        className="me-2"
                        type="checkbox"
                        id={item._id}
                        value={item._id}
                        checked={selectedTag.includes(item._id)}
                        onChange={() => handleTagChange(item._id)}
                      />
                      <label htmlFor={item._id}>
                        {activeLanguage === "ar"
                          ? item.ar_title
                          : item.en_title}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default AddBlogForm;
