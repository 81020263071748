import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  btuser_single_data_get,
  getuserTransferFund,
  get_user_wallet,
  userTransferFund,
  verifyTeamMemberUser,
  checkDailyTransactionLimits,
} from "../../../common/Api";
import { toast } from "react-toastify";
import FundTransferListComponent from "../../Component/FundTranfer/FundTransferListComponent";
import Swal from "sweetalert2";

export default function FundTransferPage() {
  const { user, token } = useSelector((state) => state.user);
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(null);
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const [users, setusers] = useState("");
  const [fund, setFund] = useState([]);
  const [disable, setDisable] = useState(true);
  const [disableAmount, setDisableAmount] = useState(true);

  const [stakingAmount, setStakingAmount] = useState("");
  const [affiliateAmount, setAffiliateAmount] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [royaltyAmount, setRoyaltyAmount] = useState("");
  const [referralAmount, setReferralAmount] = useState("");
  const [availableAmount, setAvailableAmount] = useState(-1);
  const [type, setType] = useState("");

  const selectHandleOptions = async (e) => {
    const wallet = document.querySelector("#wallet").value;
    setType(wallet);

    switch (parseInt(wallet)) {
      case 1:
        setAvailableAmount(stakingAmount);
        setDisableAmount(false);
        break;
      case 2:
        setAvailableAmount(affiliateAmount);
        setDisableAmount(false);
        break;
      case 3:
        setAvailableAmount(depositAmount);
        setDisableAmount(false);
        break;
      case 4:
        setAvailableAmount(royaltyAmount);
        setDisableAmount(false);
        break;
      case 5:
        setAvailableAmount(referralAmount);
        setDisableAmount(false);
        break;
      default:
        setAvailableAmount(0);
        setDisableAmount(true);
        break;
    }
  };

  const handleVerifyUser = async () => {
    if (email == "") {
      return toast.error("Please Enter Email");
    }
    try {
      const submitData = {
        email: email,
      };
      const data = await verifyTeamMemberUser(user._id, token, submitData);
      if (data.status == true) {
        setError("");
        setMsg(data?.data?.email);
        setDisable(false);
      } else {
        setDisable(true);
        setError(data.response.data.message);
        setMsg("");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const btGetSingleUserData = async () => {
    try {
      const data = await btuser_single_data_get(user._id, token);
      setusers(data?.data?.btbalance);

      const __walletData = await get_user_wallet(user._id, token);

      setStakingAmount(__walletData?.data?.staking);
      setAffiliateAmount(__walletData?.data?.affility);
      setDepositAmount(__walletData?.data?.deposite);
      setRoyaltyAmount(__walletData?.data.royalty);
      setReferralAmount(__walletData?.data.referral);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTransferFund = async () => {
    try {
      if (type === "") {
        return toast.error("Select at least one wallet");
      }

      // Call the API to check daily transaction limits
      const limitCheck = await checkDailyTransactionLimits(
        user._id,
        type,
        amount,
        token
      );
      if (!limitCheck.status) {
        // If the API returns a status indicating an error
        return Swal.fire({
          icon: "error",
          title: limitCheck.title || "Limit Error",
          text:
            limitCheck.message ||
            "There was an error checking the transaction limits.",
        });
      }

      if (availableAmount >= amount) {
        const submitData = {
          email: email,
          amount: amount,
          walletType: type,
        };
        const data = await userTransferFund(user._id, token, submitData);
        if (data.status == true) {
          toast.success(data.message);
          setInterval(() => {
            window.location.reload();
          }, 3000);
        }
      } else {
        return Swal.fire({
          icon: "error",
          title: "",
          text: "Insufficient Balance!",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const formatAmount = (amount) => {
    if (amount < 0) return "0.00";
    return (Math.floor(amount * 100) / 100).toFixed(2);
};

  const get_user_transfer_fund = async () => {
    try {
      const data = await getuserTransferFund(user._id, token);

      setFund(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    btGetSingleUserData();
    get_user_transfer_fund();
  }, []);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row g-6">
        <div className="col-md-4">
          <div class="staking-reward2">
            <h4 class="card-header text-white pb-4">
              Fund Transfer
              <br />
              <h6 className="pt-2">
                Total Balance :{" "}
                {formatAmount(Number(users || 0))}{" "}
                <span> METABT</span>
              </h6>
            </h4>
            <div class="card-body demo-vertical-spacing demo-only-element">
              <div class="input-group mb-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Recipient's Email"
                  aria-label="Recipient's Email"
                  aria-describedby="basic-addon13"
                  valui={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span
                  class="input-group-text btn btn-primary"
                  id="basic-addon13"
                  style={{ cursor: "pointer" }}
                  onClick={handleVerifyUser}
                >
                  Verify
                </span>
              </div>
              {error && <p style={{ color: "red" }}>{error}</p>}

              {msg && <p style={{ color: "green" }}> Active User ({msg})</p>}

              <div className="input-group mb-0">
                <select
                  className="form-control"
                  aria-label="Default select example"
                  value={type}
                  onChange={selectHandleOptions}
                  id="wallet"
                >
                  <option value="">Select Wallet</option>
                  <option value="1">
                    Staking Wallet({formatAmount(stakingAmount)}{" "}
                    <span style={{ fontSize: "13px" }}>METABT</span>)
                  </option>
                  <option value="2">
                    Affiliate Wallet({formatAmount(affiliateAmount)}{" "}
                    <span style={{ fontSize: "13px" }}>METABT</span>)
                  </option>
                  <option value="4">
                    Royalty Wallet({formatAmount(royaltyAmount)}{" "}
                    <span style={{ fontSize: "13px" }}>METABT</span>)
                  </option>
                  <option value="5">
                    Referral Wallet({formatAmount(referralAmount)}{" "}
                    <span style={{ fontSize: "13px" }}>METABT</span>)
                  </option>
                </select>
                <span className="input-group-text btn btn-primary">Wallet</span>
              </div>
              <div className="mb-4">
                {availableAmount >= 0 && (
                  <>
                    Available Quantity: {formatAmount(availableAmount)} METABT
                  </>
                )}
              </div>
              {disableAmount == false && (
                <div className="input-group mb-4">
                  <span className="input-group-text btn btn-primary">$</span>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Amount"
                    aria-label="Amount (to the nearest dollar)"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              )}

              {/* <div class="input-group">
                {disable == false && (
                  <button
                    type="button"
                    class="btn btn-success waves-effect waves-light"
                    onClick={handleTransferFund}
                  >
                    Transfer
                  </button>
                )}
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <FundTransferListComponent data={fund} />
        </div>
      </div>
    </div>
  );
}
