import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify'; // Optional for notifications
import { Link, useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import { useSelector } from 'react-redux';
import { delete_coin_pair, delete_user, get_coin_pair, get_coin_pair_status, get_user, update_user_status } from '../../common/Api';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';


const UserListComponent = () => {
    const { token } = useSelector((state) => state.user)
    const [users, setUsers] = useState([]);
    const [filteredCategory, setFilteredCategory] = useState([]);
    const [search, setSearch] = useState('');
    const [show, setShow] = useState(false);
    const [name, setName] = useState('')
    const [symbol, setSymbol] = useState('')
    const [previosValue, setPreviosValue] = useState(null)
    const [currentValue, setCurrentValue] = useState(null)
    const [volume, setVolume] = useState(null)
    const [coinPair, setCoinPair] = useState('')

    //console.log(token);

    const handleClose = () => {
        setName('')
        setSymbol('')
        setPreviosValue(null)
        setCurrentValue(null)
        setVolume(null)
        setCoinPair('')
        setShow(false)
    };

    const handleShow = (name, symbol, currentValue, previosValue, coinPair, volume) => {
        setName(name)
        setSymbol(symbol)
        setPreviosValue(previosValue)
        setCurrentValue(currentValue)
        setVolume(volume)
        setCoinPair(coinPair)
        setShow(true)
    };



    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    const get_users = async () => {
        try {
            const data = await get_user(token)
            console.log(data);
            setUsers(data.data);
            setFilteredCategory(data.data);
        } catch (error) {
            toast.error('Failed to fetch Coins.');
        }
    };

    useEffect(() => {
        get_users();
    }, []);

    useEffect(() => {
        const result = users.filter(user =>
            user.username.toLowerCase().includes(search.toLowerCase()) ||
            user.email.toLowerCase().includes(search.toLowerCase()) || 
            user.isAdmin.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredCategory(result);
    }, [search, users]);

    const handleStatus = async (id, s) => {
        try {
            const data = await update_user_status(id, s, token)

            console.log(data);
            if (data.status == true) {
                toast.success(data.message)
                get_users();
            }

        } catch (error) {
            toast.error(error.message);
        }
    };

    const handledelete = async (id) => {
        const confirm = window.confirm('Are you sure to delete this User?');
        if (confirm) {
            try {
                const data = await delete_user(id, token)
                if (data.status == true) {
                    toast.success(data.message)
                    get_users();
                }
            } catch (error) {
                toast.error(error.message);
            }
        }
    };

    const handleEdit = async (id) => {
        navigate(`/admin/edituser/${id}`);
    };

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
        },

        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },

        {
            name: 'Phone',
            selector: row => row.numnber,
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => <>
                <Badge bg="warning">
                    {row.isAdmin}
                </Badge>
            </>,
            sortable: true,
        },
        {
            name: 'Balance',
            selector: row => <>$ {row.btbalance}</>,
            sortable: true,
        },
        {
            name: 'Roles',
            selector: row => <>
                <Link to={`/admin/users-roles/${row._id}`}><Badge bg="success">
                    Roles & Permission
                </Badge></Link>
            </>
            ,
            sortable: true,
        },
        {
            name: 'Status',
            cell: row => (
                <>
                    <label className="switch">
                        <input type="checkbox" className="switch-input" checked={row.status === 1} onChange={() => handleStatus(row._id, row.status)} />
                        <span className="switch-toggle-slider">
                            {row.status === 1 ? (
                                <span className="switch-on">
                                    <i className="ti ti-check"></i>
                                </span>
                            ) : (
                                <span className="switch-off">
                                    <i className="ti ti-x"></i>
                                </span>
                            )}
                        </span>

                    </label>
                </>
            ),
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                  <Link to={`/admin/add-user/${row._id}`}>  <i className="fas fa-edit me-2" style={{ cursor: "pointer", color: "green" }}></i></Link>
                    <i className="fas fa-trash me-2" style={{ cursor: "pointer", color: "red" }} onClick={() => handledelete(row._id)}></i>
                </>
            ),
        },
    ];

    return (
        <div className='col-lg-12'>
            <div className="card mb-3">
                <h5 className="card-header">User List</h5>
                <div className="card-body">
                    <div className="mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by name , email  , type"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredCategory}
                        pagination
                        highlightOnHover
                        striped
                        
                    />
                </div>
            </div>


        </div>

    );
};

export default UserListComponent;


  
