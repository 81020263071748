import React from 'react'
import PlanForm from '../../Forms/Plan/PlanForm'
import PlanListComponent from '../../Components/Plan/PlanListComponent'


export default function PlanPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <PlanForm />
                <PlanListComponent />
            </div>
        </div>
    )
}
