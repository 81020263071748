import React from 'react'
import MywalletComponent from './MywalletComponent'
import WalletTabComponent from './WalletTabComponent'

export default function WalletPageComponent() {
    return (
        <div className='my_wallet mobile_show'>
            <div class="time_date">
                <span class="date">Thu May 30</span>
                <span class="time">7:03PM</span>
            </div>
            <h2 class="heading mb-4 text-white">My Wallet</h2>
            <MywalletComponent />
            <WalletTabComponent />
        </div>
    )
}
