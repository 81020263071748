import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "Name": "Name",
      "Education": "Education",
      "Short Description": "Short Description",
      "Specialties": "Specialties",
      "Location": "Location",
      "City": "City",
      "Area": "Area",
      "Profile": "Profile",
      "Timing": "Timing",
      "Title": "Title",
      "Image": "Image",
      "PDF": "PDF",
      "Submit": "Submit",
      "Close": "Close",
      "Title": "Title",
      "Description": "Description",
      "Status": "Status",
      "Image": "Image",
      "About": "About",
      "Notes": "Notes",
      "Question": "Question",
      "Answer": "Answer",
      "Cover": "Cover",
      "Not Cover": "Not Cover",
      'Drag and drop an image file here, or click to select one': 'Drag and drop an image file here, or click to select one',
      'Personal Healthcare': 'Personal Healthcare',
      'Meta Title': 'Meta Title',
      'Meta Description': 'Meta Description',
      'Meta Keywords': 'Meta Keywords',
      'slug': 'slug',
      'All Categories': 'All Categories',
      'Career': 'Career',
      "Procedure":"Procedure",
      "Availablity" :"Availablity",
      "availablitytitle":"Available Title",
      "footer":"Footer",
      "selectfooter" :"Select Footer",
      "url" :"Url"

    }
  },
  ar: {
    translation: {
      "Name": "اسم",
      "Education": "تعليم",
      "Short Description": "وصف قصير",
      "Specialties": "تخصصات",
      "Location": "موقعك",
      "City": "مدينة",
      "Area": "منطقة",
      "Profile": "الملف الشخصي",
      "Timing": "التوقيت",
      "Title": "عنوان",
      "Image": "صورة",
      "PDF": "بي دي إف",
      "Submit": "إرسال",
      "Close": "يغلق",
      "Title": "عنوان",
      "Status": "حالة",
      "Image": "صورة",
      "Description": "وصف",
      "About": "عن",
      "Notes": "ملحوظات",
      "Question": "سؤال",
      "Answer": "إجابة",
      "Cover": "غطاء",
      "Not Cover": "لا يغطي",
      'Drag and drop an image file here, or click to select one': 'قم بسحب وإسقاط ملف الصورة هنا، أو انقر لتحديد واحد',
      'Personal Healthcare': 'الرعاية الصحية الشخصية',
      'Meta Title': 'عنوان الفوقية',
      'Meta Description': 'ميتا الوصف',
      'Meta Keywords': 'كلمات دلالية',
      'slug': 'سبيكة',
      'All Categories': 'جميع الفئات',
      'Career': 'حياة مهنية',
      "Procedure":"إجراء" ,
      "Availablity" :"الاستفادة من كريم المحصول",
      "availablitytitle":"العنوان المتاح",
      "footer":"تذييل",
      "selectfooter" :"حدد التذييل",
       "url" :"عنوان URL"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // default language
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
