import React from 'react'
import AddBlogForm from '../../../Forms/Blog/Category/AddBlogForm'

export default function AddBlog() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <AddBlogForm />
            </div>
        </div>
    )
}
