import React from "react";
import UserListComponent from "../../Components/User/UserListComponent";
import UserListNewComponent from "../../Components/User/UserListNewComponent";

export default function UserPage() {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        {/* <UserListComponent /> */}
        <UserListNewComponent />
      </div>
    </div>
  );
}
