import React, { useEffect, useState } from "react";
import wallet from "./wallet.PNG";
import fundingwallet from "./funding-wallet.PNG";
import swallet from "./staking-wlt.PNG";
import awallet from "./afililate.PNG";
import coin from "./coins.png";
import { get_user_wallet, api_get_setting_data, userMainBalanceGet } from "../../../common/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function MywalletComponent() {
  const { user, token } = useSelector((state) => state.user);
  const [income, setIncome] = useState({});
  const [users, setUsers] = useState({});
  const [metaBTPrice, setMetaBTPrice] = useState(0.0);
  const [withdrawalamount, setWithdrawalAmount] = useState(0.0);
  const [mainbalance, setMainBalance] = useState(0.0);

  const get_usr_wallet = async () => {
    try {
      const data = await get_user_wallet(user._id, token);
      console.log("income wallte ==> ", data);
      setWithdrawalAmount(data?.withdrawal)
      setIncome(data?.data);
      setUsers(data?.user);
    } catch (error) {
      toast.error(error);
    }
  };

  const getSettingData = async () => {
    try {
      const data = await api_get_setting_data(token);
      // console.log(data.data);
      setMetaBTPrice(data.data?.usd_price);
    } catch (error) {
      //toast.error("Failed to fetch settings data.");
    }
  };

  const user_get_main_balance = async () => {
    try {
      const data = await userMainBalanceGet(user._id)
      //console.log(data)
      setMainBalance(data.balance)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getSettingData();
    get_usr_wallet();
    user_get_main_balance()
  }, []);

  const formatAmount = (amount) => {
    if (amount < 0) return "0.00";
    const truncatedAmount = Math.floor(amount * 100) / 100;
    return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(truncatedAmount);
};

  return (
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4">
          <div class="my_balance_card">
            <div class="info">
              <h4>Main Balance</h4>
              <h2 className="" style={{ fontSize: "14px" }}>
                {formatAmount(Number(mainbalance || 0))}{" "}
                <span style={{ fontSize: "10px" }}>METABT</span>
              </h2>
              <span className="d-flex mb-4">{formatAmount(mainbalance * metaBTPrice)} USDT</span>
              <br />
              <p class="mr-top">
                <b>{users.btucode}</b>
              </p>
              <h5 className="mb-5">{users.username}</h5>
            </div>
            <div class="koins">
              <img src={coin} />
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="deposite_sections">
            <div class="deposite_wallet">
              <div class="dep_icon">
                <img src={wallet} />
              </div>
              <div class="dep_content">
                <h4>Deposit Wallet</h4>
                <h2 style={{ fontSize: "20px" }}>
                  {formatAmount(income?.deposite)}{" "}
                  <span style={{ fontSize: "13px" }}>METABT</span>
                </h2>
              </div>
            </div>
          </div>
          <div class="deposite_sections">
            <div class="deposite_wallet">
              <div class="dep_icon">
                <img src={fundingwallet} />
              </div>
              <div class="dep_content">
                <h4>Withdrawal Wallet</h4>
                <h2 style={{ fontSize: "20px" }}>
                  {" "}
                  {formatAmount(Math.abs(withdrawalamount))}{" "}
                  <span style={{ fontSize: "13px" }}>METABT</span>
                </h2>
              </div>
            </div>
          </div>
          <div class="deposite_sections">
            <div class="deposite_wallet">
              <div class="dep_icon">
                <img src={fundingwallet} />
              </div>
              <div class="dep_content">
                <h4>Royalty Wallet</h4>
                <h2 style={{ fontSize: "20px" }}>
                  {" "}
                  {formatAmount(income?.royalty)}{" "}
                  <span style={{ fontSize: "13px" }}>METABT</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="deposite_sections">
            <div class="deposite_wallet">
              <div class="dep_icon">
                <img src={swallet} />
              </div>
              <div class="dep_content">
                <h4>Staking Wallet</h4>
                <h2 style={{ fontSize: "20px" }}>
                  {" "}
                  {formatAmount(
                    isNaN(Number(income?.staking)) ? 0 : income?.staking
                  )}{" "}
                  <span style={{ fontSize: "13px" }}> METABT </span>
                </h2>
              </div>
            </div>
          </div>
          <div class="deposite_sections">
            <div class="deposite_wallet">
              <div class="dep_icon">
                <img src={awallet} />
              </div>
              <div class="dep_content">
                <h4>Affiliate Wallet</h4>
                <h2 style={{ fontSize: "20px" }}>
                  {" "}
                  {formatAmount(
                    isNaN(Number(income?.affility)) ? 0 : income?.affility
                  )}{" "}
                  <span style={{ fontSize: "13px" }}> METABT </span>
                </h2>
              </div>
            </div>
          </div>
          <div class="deposite_sections">
            <div class="deposite_wallet">
              <div class="dep_icon">
                <img src={awallet} />
              </div>
              <div class="dep_content">
                <h4>Referral Wallet</h4>
                <h2 style={{ fontSize: "20px" }}>
                  {" "}
                  {formatAmount(
                    isNaN(Number(income?.referral)) ? 0 : income?.referral
                  )}{" "}
                  <span style={{ fontSize: "13px" }}> METABT </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
