import React from 'react';

const NotFoundPage = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1 style={{ fontSize: '48px' }}>404</h1>
      <h2>Page Not Found</h2>
      <p>The page you're looking for doesn't exist.</p>
      <a href="/" style={{ textDecoration: 'underline', color: 'blue' }}>Go Back Home</a>
    </div>
  );
};

export default NotFoundPage;