import React from 'react'
import SupportComponent from '../../Component/Support/SupportComponent'

export default function SupportPage() {
  return (
    <div>
      <SupportComponent />
    </div>
  )
}
