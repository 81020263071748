import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../Services/UserSlices";
import { ToastContainer, toast } from "react-toastify";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import btlogo from "../../btclogo.png";
import { add_user } from "../../common/Api";
import Swal from "sweetalert2";

export default function RegisterPage() {
  const params = useParams();

  const { ucode } = params;

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const { token, user } = useSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setComnfirmPassword] = useState(false);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setComnfirmPassword(!confirmPassword);
  };

  const initialValues = {
    email: "",
    password: "",
    referal: ucode ? ucode : "",
    captchaInput: ""
  };

  // CAPTCHA state
  const [captcha, setCaptcha] = useState("");
  const [captchaAnswer, setCaptchaAnswer] = useState("");

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptcha(`${num1} + ${num2}`);
    setCaptchaAnswer(num1 + num2);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Please enter user email")
      .email("Invalid email format"),
    password: Yup.string()
      .required("Please enter password")
      .min(6, "Password must be at least 6 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
      ),
    confirmPassword: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    referal: Yup.string().required("Please Enter Referral Code!"),
    captchaInput: Yup.string()
      .required("Please solve the CAPTCHA")
      .test("captcha-match", "CAPTCHA is incorrect", function (value) {
        return value == captchaAnswer; // Check if input matches answer
      }),
  });

  const handleLogin = async (formValue) => {
    const { email, password, referal, confirmPassword } = formValue;

    if (password != confirmPassword) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Password or Confirm Password do not match!",
      });
    }
    if (referal == "") {
      return Swal.fire({
        icon: "error",
        title: "Missing Information",
        text: "Please Enter Referral Code!",
      });
    }
    const data = { email, password: password, btreferal: referal };

    setLoading(true);
    toast.dismiss();
    try {
      const resultAction = await add_user(data);
      console.log(resultAction);
      if (resultAction.status == true) {
        setMsg(
          "Thank you for registering! Please check your email to verify your account and log in."
        );
        // toast.success(resultAction.message);
        setInterval(() => {
          navigate("/");
        }, 3000);
      } else {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: resultAction.response.data.message,
        });
      }
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" />
      <section class="login_form_sec">
        <div className="container-xxl">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner py-4">
              <div className="card bg-transparent">
                <div class="d-flex logo justify-content-center pb-3">
                  <img src={btlogo} width="200px" class="logo" />
                  {/* <div class="logo-text text-white">
                    <h3 class="mb-0">BTCASH</h3>
                    <p>BUILD YOUR OWN COMMUNITY</p>
                  </div> */}
                </div>
                <div className="card-body staking-reward2 border-0 rounded-0">
                  <p className="fw-bold text-white">Register Your Account</p>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleLogin}
                  >
                    <Form className="mb-3 login-form">
                      <div className="mb-3">
                        <label className="form-label">Email ID *</label>
                        <Field
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="Enter your email"
                        />
                        <ErrorMessage
                          name="email"
                          component="span"
                          className="errorInput"
                        />
                      </div>
                      <div className="mb-3 form-password-toggle">
                        <div className="d-flex justify-content-between">
                          <label className="form-label">Password *</label>
                        </div>
                        <div className="input-group input-group-merge">
                          <Field
                            name="password"
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                          />
                          <span
                            className="input-group-text cursor-pointer"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? (
                              <i className="ti ti-eye"></i>
                            ) : (
                              <i className="ti ti-eye-off"></i>
                            )}
                          </span>
                        </div>
                        <small className="text-muted">
                          Requires uppercase, lowercase, number & symbol
                        </small>
                        <br />
                        <ErrorMessage
                          name="password"
                          component="span"
                          className="errorInput"
                        />
                      </div>

                      <div className="mb-3 form-password-toggle">
                        <div className="d-flex justify-content-between">
                          <label className="form-label">
                            Confirm Password *
                          </label>
                        </div>
                        <div className="input-group input-group-merge">
                          <Field
                            name="confirmPassword"
                            type={confirmPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                          />
                          <span
                            className="input-group-text cursor-pointer"
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            {confirmPassword ? (
                              <i className="ti ti-eye"></i>
                            ) : (
                              <i className="ti ti-eye-off"></i>
                            )}
                          </span>
                        </div>
                        <ErrorMessage
                          name="confirmPassword"
                          component="span"
                          className="errorInput"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Referral Code *</label>
                        <Field
                          name="referal"
                          type="text"
                          className="form-control"
                          placeholder="Referral Code"
                        />
                        <ErrorMessage
                          name="referal"
                          component="span"
                          className="errorInput"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Solve CAPTCHA: <span style={{ background: "linear-gradient(rgb(54, 47, 116), rgb(57, 34, 88))", color: "white", padding: "2px 4px" }}>{captcha} </span>
                        </label>
                        <Field
                          name="captchaInput"
                          type="text"
                          className="form-control"
                          placeholder="Enter the result"
                        />
                        <ErrorMessage
                          name="captchaInput"
                          component="span"
                          className="errorInput"
                        />
                      </div>

                      <div className="mb-3">
                        <button
                          type="submit"
                          className="border-0 rounded d-grid w-100 loginBtn d-flex gap-1 align-items-center justify-content-center"
                          disabled={loading}
                        >
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                          <span>Register</span>
                        </button>
                      </div>
                    </Form>
                  </Formik>
                </div>
                <div class="register text-center pt-3">
                  <p>
                    Already Account?{" "}
                    <span>
                      {" "}
                      <Link
                        to="/login"
                        className="fw-bold"
                        style={{ color: "#ACA4EB" }}
                      >
                        Login
                      </Link>
                    </span>
                  </p>
                  {msg && (
                    <p style={{ color: "green", textAlign: "center" }}>{msg}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
