
// export const url = 'http://localhost:5000';
//  export const assetUrl = 'http://localhost:5000'
//  export const Image = 'http://localhost:5000/uploads'
//  export const BASE_URL = 'http://localhost:5000/' 

export const url = 'https://qbitdex.io:5000';
export const assetUrl = 'https://qbitdex.io:5000'
export const Image = 'https://qbitdex.io:5000'
export const BASE_URL = 'https://qbitdex.io:5000/'





