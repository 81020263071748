import React from 'react'
import AddCategoryForm from '../../../Forms/Blog/Category/AddCategoryForm'
import CategoryListComponent from '../../../Components/Blog/Category/CategoryListComponent'

export default function AddCategory() {
  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className='col-lg-4'>
            <AddCategoryForm />
          </div>
          <div className='col-lg-8'>
            <CategoryListComponent />
          </div>
        </div>
      </div>

    </>

  )
}
