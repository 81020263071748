import React, { useEffect, useState } from "react";
import { get_ticker_data } from "../../common/Api";
import "./Footer.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import dashboardI from "./dashboard.svg";
import walletI from "./wallet.svg";
import stakingI from "./staking.svg";
import transactionI from "./transaction.svg";
import TeamDetailsI from "./TeamDetails.svg";

export default function FooterComponent() {
  const [coins, setCoins] = useState([]);
  const { user, token } = useSelector((state) => state.user);
  const ticker_data = async () => {
    const data = await get_ticker_data();
    setCoins(data.data.coins);
  };

  useEffect(() => {
    ticker_data();
  }, []);

  return (
    <>
      <div
        id="footer"
        style={{ borderTop: "1px solid rgba(230, 236, 245, 0.173)" }}
      >
        <div className="listAllIcone" style={{ margin: "20px" }}>
          <div class="ticker-wrap">
            <div class="tickers">
              <span class="item-collections-1 horizontal-scrolling-items">
                {coins?.map((items, i) => {
                  return (
                    <span class="item horizontal-scrolling-items__item" key={i}>
                      <div class="inline_section">
                        <div class="icon">
                          <img
                            src={items?.iconUrl}
                            width={"10px"}
                            style={{ width: "40px" }}
                          />
                          <div class="tet" style={{ fontSize: "13px" }}>
                            {items?.name}
                            <br />{" "}
                            <span style={{ fontSize: "12px" }}>
                              {items?.symbol}
                            </span>
                          </div>
                        </div>
                        <div class="price" style={{ fontSize: "13px" }}>
                          {items?.price}
                          <br />
                          <span className={items?.change < 0 ? `red` : `green`}>
                            {items?.change < 0 ? (
                              <i
                                className="fas fa-arrow-down"
                                style={{ color: "red" }}
                              ></i>
                            ) : (
                              <i
                                className="fas fa-arrow-up"
                                style={{ color: "green" }}
                              ></i>
                            )}
                            {items?.change}
                          </span>
                        </div>
                      </div>
                    </span>
                  );
                })}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom-wrapper d-block d-lg-none d-md-none">
        <div className="container-fluid ">
          <div className="main-row">
            <div className="main-row-child">
              <div className="footer-lastMenus text-center">
                <Link to="/user/dashboard" className="text-center">
                  <h6 className="mb-0 pb-2">Dashboard</h6>
                  <img src={dashboardI} alt="" />
                </Link>
              </div>
            </div>
            <div className="main-row-child">
              <div className="footer-lastMenus text-center">
                <Link to="/user/wallet" className="text-center">
                  <h6 className="mb-0 pb-2">Wallet</h6>
                  <img src={walletI} alt="" />
                </Link>
              </div>
            </div>
            <div className="main-row-child">
              <div className="footer-lastMenus text-center">
                <Link to="/user/buyplan" className="text-center">
                  <h6 className="mb-0 pb-2">Staking</h6>
                  <img src={stakingI} alt="" />
                </Link>
              </div>
            </div>
            <div className="main-row-child">
              <div className="footer-lastMenus text-center">
                <Link to="/user/transaction" className="text-center">
                  <h6 className="mb-0 pb-2">Transactions</h6>
                  <img src={transactionI} alt="" style={{ width: "2rem" }} />
                </Link>
              </div>
            </div>
            <div className="main-row-child">
              <div className="footer-lastMenus text-center">
                <Link to="/user/network" className="text-center">
                  <h6 className="mb-0 pb-2">Team Details</h6>
                  <img src={TeamDetailsI} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
