import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { logout } from "../../Services/UserSlices";
import btclogo from "../assets/img/btclogo.png";
import dashboardI from './img/dashboard.svg'
import walletI from "./img/wallet.svg";
import transactionI from "./img/transaction.svg";
import rewardI from "./img/reward.svg";
import rankI from "./img/ranking.svg";
import fundI from "./img/fund.svg";
import helpI from "./img/help.svg";
import networkI from "./img/network.svg";
import logoutI from "./img/logout.svg";
import sunI from "./img/sun.svg";
import rightI from "./img/right.svg";
import team from "./img/team.svg";


export default function SideBarComponents() {
  const { user, roles, setting, token } = useSelector((state) => state.user);
  const [category, setCategory] = useState(false);
  const [income, setIncome] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleIncomeToggle = (e) => {
    e.preventDefault();
    setIncome(!income);
  };

  const handleCategoryToggle = (e) => {
    e.preventDefault();
    setCategory(!category);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false); // Close sidebar if clicked outside
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [token, navigate]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const changeMode = (event) => {
    const isChecked = event.target.checked;
    const cssLinks = document.querySelectorAll('.themeStyles'); // Select all relevant <link> tags

    const lightThemes = [
      '/assets/vendor/css/rtl/core.css',
      '/assets/vendor/css/rtl/theme-default.css',
    ];

    const darkThemes = [
      '/assets/vendor/css/rtl/core-dark.css',
      '/assets/vendor/css/rtl/theme-default-dark.css',
    ];

    const themesToApply = isChecked ? lightThemes : darkThemes;

    cssLinks.forEach((link, index) => {
      link.href = themesToApply[index]; // Update each link href
    });
  };


  return (
    <aside
      ref={sidebarRef}
      id="layout-menu"
      className={`layout-menu menu-vertical menu bg-menu-theme ${isOpen ? "open" : ""
        }`}
      style={{
        borderRight: "1px solid #e6ecf52c",
        width: isOpen ? "250px" : "99px",
        textAlign: "center",
        transition: "width 0.3s",
      }}
    >
      <div className="app-brand demo mt-4" style={{ margin: "auto" }}>
        <Link to="/user/dashboard" className="app-brand-link">
          <span className="app-brand-logo demo">
            <img
              className="mt-2 mb-2"
              src={btclogo}
              width="40px"
              alt="BTC Logo"
            />
          </span>
        </Link>
      </div>
      <p>
        <b>BTCASH</b>
      </p>

      <button
        className="layout-menu-toggle menu-link text-large ms-auto w-100"
        onClick={handleToggle}
        style={{ background: "none", border: "none" }}
      >
        {isOpen ? (
          <i className="bg-transparent d-flex justify-content-center align-items-center">
            <img src={rightI} alt="" className="" style={{ width: '22px', rotate: '180deg' }} />
          </i>
        ) : (
          <i className="bg-transparent d-flex justify-content-center align-items-center">
            <img src={rightI} alt="" className="" style={{ width: '22px' }} />
          </i>
        )}
      </button>

      <div className="menu-inner-shadow"></div>
      <ul className="menu-inner py-1">
        <li className="menu-item open">
          <Link to="/user/dashboard" className="menu-link sidemenu-dash">
            {/* <i class="menu-icon tf-icons ti ti-smart-home"></i> */}
            <i className="bg-transparent d-flex justify-content-center align-items-center">
              <img src={dashboardI} alt="" className="" style={{ width: '22px' }} />
            </i>
            <div data-i18n="Dashboard">Dashboard</div>
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/user/buyplan" className="menu-link sidemenu-dash">
            {/* <i className="fa-solid fa-bars"></i> */}
            <i className="bg-transparent d-flex justify-content-center align-items-center">
              <img src={rankI} alt="" className="" style={{ width: '25px' }} />
            </i>
            <div data-i18n="Stake Plan">Stake Plan</div>
          </Link>
        </li>

        <li className="menu-item">
          <Link to="/user/staking-history" className="menu-link sidemenu-dash">
            {/* <i className="fa-solid fa-trophy"></i> */}
            <i className="bg-transparent d-flex justify-content-center align-items-center">
              <img src={rewardI} alt="" className="" style={{ width: '25px' }} />
            </i>
            <div data-i18n="Staking History">Staking Reward </div>
          </Link>
        </li>

        <li className="menu-item">
          <Link to="/user/transaction" className="menu-link sidemenu-dash">
            {/* <i class="menu-icon tf-icons ti ti-text-wrap-disabled"></i> */}
            <i className="bg-transparent d-flex justify-content-center align-items-center">
              <img src={transactionI} alt="" className="" style={{ width: '35px' }} />
            </i>
            <div data-i18n="Transactions">Transactions</div>
          </Link>
        </li>

        <li className="menu-item">
          <Link to="/user/wallet" className="menu-link sidemenu-dash">
            {/* <i class="menu-icon tf-icons ti ti-wallet"></i> */}
            <i className="bg-transparent d-flex justify-content-center align-items-center">
              <img src={walletI} alt="" className="" style={{ width: '25px' }} />
            </i>
            <div data-i18n="Wallet">Wallet</div>
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/user/fund/transfer" className="menu-link sidemenu-dash">
            {/* <i class="menu-icon tf-icons ti ti-layout-kanban"></i> */}
            <i className="bg-transparent d-flex justify-content-center align-items-center">
              <img src={fundI} alt="" className="" style={{ width: '25px' }} />
            </i>
            <div data-i18n="Fund Transfer">Fund Transfer</div>
          </Link>
        </li>

        <li className="menu-item">
          <Link to="/user/my-team" className="menu-link sidemenu-dash">
            {/* <i class="menu-icon ti ti-network"></i> */}
            <i className="bg-transparent d-flex justify-content-center align-items-center">
              <img src={team} alt="" className="" style={{ width: '25px' }} />
            </i>
            <div data-i18n="Team">Team</div>
          </Link>
        </li>

        <li className="menu-item">
          <Link to="/user/direct-network" className="menu-link sidemenu-dash">
            {/* <i class="menu-icon ti ti-network"></i> */}
            <i className="bg-transparent d-flex justify-content-center align-items-center">
              <img src={networkI} alt="" className="" style={{ width: '25px' }} />
            </i>
            <div data-i18n="Network">Network</div>
          </Link>
        </li>

        <li className="menu-item">
          <Link to="/user/support" className="menu-link sidemenu-dash">
            {/* <i className="fa-solid fa-question"></i> */}
            <i className="bg-transparent d-flex justify-content-center align-items-center">
              <img src={helpI} alt="" className="" style={{ width: '25px' }} />
            </i>
            <div data-i18n="Help Desk">Help Desk</div>
          </Link>
        </li>

        <li className="menu-item">
          <Link className="menu-link sidemenu-dash" onClick={handleLogout}>
            {/* <i className="menu-icon tf-icons ti ti-logout"></i> */}
            <i className="bg-transparent d-flex justify-content-center align-items-center">
              <img src={logoutI} alt="" className="" style={{ width: '20px' }} />
            </i>
            <div data-i18n="Logout">Logout</div>
          </Link>
        </li>
        {/* <li className="pt-5">
          <div class="d-flex justify-content-center align-items-center gap-2 px-0 w-100 ps-2">
            <div className="w-100">
              <img src={sunI} alt="" style={{ width: '20px' }} />
            </div>
            <label class="switch switch-primary me-0">
              <input type="checkbox" class="switch-input" id="modeChanges" onChange={changeMode} />
              <span class="switch-toggle-slider">
                <span class="switch-on"></span>
                <span class="switch-off"></span>
              </span>
            </label>
          </div>
        </li> */}
      </ul>
    </aside>
  );
}
