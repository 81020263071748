import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { url } from "../Common/Url";
import { Link, useNavigate } from "react-router-dom";
import UserProfile from "./UserProfile";
import BarChart from "../../BtCash/Page/Dashboard/BarChart";
import TwoLineChart from "../../BtCash/Page/Dashboard/TwoLineChart";
import LineChart from "../../BtCash/Page/Dashboard/Char";

import "../../BtCash/Page/Dashboard/Dashboard.css";
import mapImg from "../../Components/Common/img/map.png";

export default function DashboardComponent() {
  const { user, token, setting } = useSelector((state) => state.user);
  const refesh = localStorage.getItem('refresh')
  const [todaystake, setTodaSTake] = useState("");
  const [todaystakedata, setTodayStakeData] = useState([]);
  const [todayaffilaiteData, setTodayAffilaiteData] = useState([]);
  const [totalStakingdata, setTotalStakingData] = useState([]);
  const [totalaffiliatedata, setTotalAffiliateData] = useState([]);
  const [todayaffiliate, setTodayAffiliate] = useState("");
  const [totalStake, setTotalStake] = useState("");
  const [totalaffiliate, setTotalAffiliate] = useState("");
  const [totalDeposit, setTotalDeposit] = useState("");
  const [totalWithdrawal, setTotalWithdrawal] = useState("");

  const navigate = useNavigate();

  const get_admin_dashboard_data = async () => {
    const { data } = await axios.get(
      `${url}/api/v2/bt/income/get-admin-stats/`,
      {
        headers: {
          token: `${token}`,
        },
      }
    );
    console.log(" response  ==> ", data);
    setTodaSTake(data?.tstake?.totalAmount);
    setTodayAffiliate(data?.tstake?.todayAffiliateAmount);
    setTotalAffiliate(data?.tstake?.totalAffiliateAmount);
    setTotalStake(data?.tstake?.totalStakingAmount);
    setTodayStakeData(data?.tstake?.todayData);
    setTodayAffilaiteData(data?.tstake?.todayAffiliateData);
    setTotalStakingData(data?.tstake?.totalStakingData);
    setTotalAffiliateData(data?.tstake?.totalAffiliateData);
    setTotalDeposit(data?.totalDeposit);
    setTotalWithdrawal(data?.totalWithdrawal);
  };

  useEffect(() => {
    if (user._id && token) {
      if (refesh) {
        localStorage.removeItem('refresh')
        window.location.reload();
      }
      get_admin_dashboard_data();
    } else {
      navigate('/')
    }
  }, [user._id, token]);

  // Function to format numbers to two decimal places
  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  return (
    <>
      <div class="container-xxl flex-grow-1">
        <div className="row flex-lg-row flex-column-reverse dashboard_item">
          <div className="col-lg-9 pt-0 pt-lg-4 pt-md-4 pb-5">
            <div className="text-left">
              <h3 className="text-white">Welcome to Dashboard</h3>
              {/* <p>Hi, {user?.username}</p> */}
            </div>
            <div className="row gy-2 gx-2">
              <div className="col-lg-6 col-md-6 col-12 h-100">
                <div className="row gy-2 gx-2">
                  <div className="col-6">
                    <div className="staking-reward h-100">
                      <div className="staking-reward_logo d-flex align-items-center">
                        <i className="fa-solid fa-layer-group"></i>
                        <p>TODAY STAKING REWARD</p>
                      </div>
                      <div className="chart-container">
                        <div className="value-display">
                          {formatAmount(todaystake)}{" "}
                          <span style={{ fontSize: "13px" }}>METABT</span>
                        </div>
                        <LineChart
                          datas={todaystakedata}
                          color="rgb(8, 217, 154)"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="staking-reward h-100">
                      <div className="staking-reward_logo d-flex align-items-center">
                        <i className="fa-solid fa-bullhorn"></i>
                        <p>TODAY AFFILIATE REWARD</p>
                      </div>
                      <div className="chart-container">
                        <div className="value-display">
                          {formatAmount(todayaffiliate)}{" "}
                          <span style={{ fontSize: "13px" }}>METABT</span>
                        </div>
                        <LineChart
                          datas={todayaffilaiteData}
                          color="rgb(237, 27, 3)"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="staking-reward h-100">
                      <div className="staking-reward_logo d-flex align-items-center">
                        <i className="fa-solid fa-scale-balanced"></i>
                        <p>TOTAL STAKE REWARD</p>
                      </div>
                      <div className="chart-container">
                        <div className="value-display">
                          {formatAmount(totalStake)}{" "}
                          <span style={{ fontSize: "13px" }}>METABT</span>
                        </div>
                        <LineChart
                          datas={totalStakingdata}
                          color="rgb(222, 98, 140)"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="staking-reward h-100">
                      <div className="staking-reward_logo d-flex align-items-center">
                        <i className="fa-solid fa-layer-group"></i>
                        <p>TOTAL AFFILIATE REWARD</p>
                      </div>
                      <div className="chart-container">
                        <div className="value-display">
                          {formatAmount(totalaffiliate)}{" "}
                          <span style={{ fontSize: "13px" }}>METABT</span>
                        </div>
                        <LineChart
                          datas={totalaffiliatedata}
                          color="rgb(200, 98, 222)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="staking-reward2 h-100">
                  <div className="chart-container2">
                    <div className="d-flex justify-content-between">
                      <p>Staking / Affiliate</p>
                    </div>
                    <TwoLineChart
                      datas1={totalStakingdata}
                      datas2={totalaffiliatedata}
                      color="rgb(200, 98, 222)"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="staking-reward2 h-100">
                  <div className="chart-container2">
                    <div className="d-flex justify-content-between">
                      <p>Staking / Affiliate</p>
                    </div>
                    <BarChart
                      datas1={totalStakingdata}
                      datas2={totalaffiliatedata}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card staking-reward">
                  <div class="map-container">
                    <img src={mapImg} className="img-fluid" />
                    <div class="point venezuela tippy" title="Venezuela"></div>
                    <div class="point brasil tippy" title="Brasil"></div>
                    <div class="point argentina tippy" title="Argentina"></div>
                    <div class="point colombia tippy" title="Colombia"></div>
                    <div class="point panama tippy" title="Panamá"></div>
                    <div class="point mexico tippy" title="Mexico"></div>
                    <div class="point usa tippy" title="Estados Unidos"></div>
                    <div class="point arabia tippy" title="Arabia Saudi"></div>
                    <div class="point turquia tippy" title="Turquía"></div>
                    <div class="point rusia tippy" title="Rusia"></div>
                    <div class="point china tippy" title="China"></div>
                    <div class="point japon tippy" title="Japon"></div>
                    <div class="point australia tippy" title="Australia"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <UserProfile  totalDeposit={totalDeposit} totalWithdrawal={totalWithdrawal}/>
          </div>
        </div>
      </div>
    </>
  );
}
