import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { add_new_coin, add_new_roles, single_roles, update_coin_pair, update_roles } from '../../common/Api';

export default function RolesForm() {

    const { token } = useSelector((state) => state.user);
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const params = useParams()
    const { id } = params;

    const handleSettingPage = async (e) => {

        e.preventDefault();

        if (name == '') {
            return toast.error('Please Enter The name of Role')
        }
        const submitData = {
            name: name,
            url: url,
        }
        try {
            const data = await add_new_roles(submitData, token)

            if (data.status == true) {
                toast.success(data.message)
                setInterval(() => {
                    window.location.reload();
                }, 2000)
            } else {
                toast.error(data.response.data.message)
            }

        } catch (error) {
            toast.error(error.message);
        }
    };


    const get_single_role = async () => {
        try {
            const data = await single_roles(id, token)
            console.log(data)
            setName(data.data.name)
            setUrl(data.data.url)

        } catch (error) {
            toast.error(error.message);
        }
    }

    useEffect(() => {
        if (id) {
            get_single_role()
        } else {
            setName('')
            setUrl('')

        }

    }, [id])



    const handleUpdateCoin = async (e) => {
        e.preventDefault();
        if (name == '') {
            return toast.error('Please Enter The name of Role')
        }
        const submitData = {
            name: name,
            url: url,
        }
        try {
            const data = await update_roles(submitData, id, token)

            if (data.status == true) {
                toast.success(data.message)
                setInterval(() => {
                    window.location.reload();
                }, 2000)
            } else {
                toast.error(data.response.data.message)
            }

        } catch (error) {
            toast.error(error.message);
        }
    }


    return (
        <div className="staking-reward2 mb-3">
            <ToastContainer />
            <div className="card-header pt-2">
                <ul className="nav nav-tabs card-header-tabs" role="tablist">
                    <li className="nav-item mb-2" role="presentation">
                        <button
                            className='nav-link active'

                        >
                            Page & Url
                        </button>
                    </li>
                </ul>
            </div>

            <div className="tab-content">
                <div className="tab-pane fade active show card-body" role="tabpanel">
                    {
                        id && <>
                            Edit Role <b>"{name}"</b>
                        </>
                    }
                    <form onSubmit={id ? handleUpdateCoin : handleSettingPage}>
                        <div className="row g-3">
                            <div className="col-md-12">
                                <label className="form-label">
                                    Page Name
                                </label>
                                <input
                                    type='text'
                                    className="form-control"
                                    name='name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}

                                />
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">
                                    URL
                                </label>
                                <input
                                    type='text'
                                    className="form-control"
                                    name='symbol'
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}

                                />
                            </div>


                        </div>


                        <div className="pt-4">
                            <button type="submit" className="rounded border-0 loginBtn addUserBTn me-sm-3 me-1 waves-effect">
                                {id ? `Update Page ${name}` : 'Add Page'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
