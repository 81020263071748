import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify'
import { get_role, get_single_user, submit_roles, update_user_role } from '../../common/Api';
import { useParams } from 'react-router-dom';

export default function UserRolePage() {
    const { token } = useSelector((state) => state.user)
    const [roles, setRoles] = useState([]);
    const [user, setUser] = useState({});
    const [selectedRoles, setSelectedRoles] = useState([]);
    const params = useParams();
    const { id } = params;

    // Fetch roles and user data
    const get_roles = async () => {
        try {
            const data = await get_role(token);
            console.log(data);
            setRoles(data.data);
        } catch (error) {
            toast.error('Failed to fetch Roles.');
        }
    };

    const get_single_users = async () => {
        try {
            const data = await get_single_user(id, token);
            console.log(data);
            setUser(data.data);
            setSelectedRoles(data.data.permission || []); // Initialize selectedRoles from user.permissions
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        get_roles();
        get_single_users();
    }, [id]);

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        setSelectedRoles((prevSelectedRoles) =>
            event.target.checked
                ? [...prevSelectedRoles, value]
                : prevSelectedRoles.filter((role) => role !== value)
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const datas = {
            selectedRoles: selectedRoles
        };
        try {
            const data = await submit_roles(id, token, datas);
            console.log(data);
            if (data.status === true) {
                toast.success(data.message);
                // Optionally, refresh user data to reflect changes without reloading the page
                get_single_users();
            }
        } catch (error) {
            console.log(error);
            toast.error('Failed to update roles.');
        }
    };

    const handleRoleUpdate = async()=>{
        const roles = document.querySelector("#role").value;
        const datas = {
            roles : roles
        }
          try {
            const data = await update_user_role(id, token, datas);
            if (data.status === true) {
                toast.success(data.message);
                get_single_users()
            }
          } catch (error) {
            toast.error(error);
          }
    }

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card mb-3">
                        <ToastContainer />

                        <div className="card-body">
                            <div className='update-permission mb-4'>
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className='mb-2'><b>Selct Role</b></label>
                                        <select className='form-control' id="role">
                                            <option>Select Role</option>
                                            {
                                                user.isAdmin === 'sub-admin' ? <>
                                                    <option value='sub-admin' selected>Sub Admin</option>
                                                    <option value='user' >User</option>
                                                </> : <>
                                                    <option value='sub-admin' >Sub Admin</option>
                                                    <option value='user' selected>User</option>
                                                </>
                                            }

                                        </select>
                                    </div>
                                    <div className="col-lg-3">
                                        <button type="submit" className="btn btn-primary me-sm-3 me-1 waves-effect waves-light mt-4" onClick={handleRoleUpdate}>Update</button>
                                    </div>
                                </div>

                            </div>
                            <hr></hr>
                            <h6>Give  Permission <b>{user?.email}</b></h6>
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    {roles.map((item) => (
                                        <div className='col-lg-2 mb-2' key={item._id}>
                                            <input
                                                type="checkbox"
                                                id={`role-${item._id}`}
                                                name="roles"
                                                value={item.url}
                                                onChange={handleCheckboxChange}
                                                className='me-2'
                                                checked={selectedRoles.includes(item.url)} // Check if role is in selectedRoles
                                            />
                                            <label htmlFor={`role-${item._id}`}>{item.name}</label>
                                        </div>
                                    ))}
                                </div>
                                <button type="submit" className="btn btn-primary me-sm-3 me-1 waves-effect waves-light mt-4">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
