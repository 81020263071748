import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../Services/UserSlices";
import { user_notifications } from "../../common/Api";
import moment from "moment";
import btclogo from "../assets/img/btclogo.png";
import { assetUrl, Image } from "../../Components/Common/Url";
import SweetAlert2 from "react-sweetalert2";
import dashboardI from "./img/dashboard.svg";
import walletI from "./img/wallet.svg";
import stakingI from "./img/staking.svg";
import transactionI from "./img/transaction.svg";
import TeamDetailsI from "./img/TeamDetails.svg";
import rewardI from "./img/reward.svg";
import rankI from "./img/ranking.svg";
import fundI from "./img/fund.svg";
import helpI from "./img/help.svg";
import networkI from "./img/network.svg";
import logoutI from "./img/logout.svg";
import sunI from "./img/sun.svg";
import rightI from "./img/right.svg";
import teamI from "./img/team.svg";

export default function UserHeaderComponent() {
  const { user, token } = useSelector((state) => state.user);
  const [not, setNot] = useState([]);
  const sidebarRef = useRef(null);

  const get_user_notifcation = async () => {
    try {
      const data = await user_notifications(user._id, token);
      setNot(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false); // Close sidebar if clicked outside
    }
  };

  const handleClickInside = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    get_user_notifcation();

    // Add click event listener to document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //console.log(not);

  const [swalProps, setSwalProps] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    setSwalProps({
      show: true,
      title: "Are you sure?",
      text: "You will be logged out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, log me out!",
      cancelButtonText: "Cancel",
    });

    //console.log("open page");
  };

  const confirmLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const handleCancel = () => {
    // Reset swalProps.show after canceling
    setSwalProps({ ...swalProps, show: false });
    console.log("Cancelled logout");
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen); // Toggle the sidebar open/close
  };

  const closeToggle = () => {
    setIsOpen(false); // Toggle the sidebar open/close
  };

  const changeMode = (event) => {
    const isChecked = event.target.checked;
    const cssLinks = document.querySelectorAll(".themeStyles"); // Select all relevant <link> tags

    const lightThemes = [
      "/assets/vendor/css/rtl/core.css",
      "/assets/vendor/css/rtl/theme-default.css",
    ];

    const darkThemes = [
      "/assets/vendor/css/rtl/core-dark.css",
      "/assets/vendor/css/rtl/theme-default-dark.css",
    ];

    const themesToApply = isChecked ? lightThemes : darkThemes;

    cssLinks.forEach((link, index) => {
      link.href = themesToApply[index]; // Update each link href
    });
  };

  return (
    <>
      <aside
        ref={sidebarRef}
        id="layout-menu"
        className={`layout-menu menu-vertical menu bg-menu-theme d-block d-lg-none d-md-none ${
          isOpen ? "open" : ""
        }`}
        style={{
          borderRight: "1px solid #e6ecf52c",
          width: isOpen ? "250px" : "100px", // Adjust width based on state
          textAlign: "center",
          transition: "width 0.3s",
        }}
      >
        <div className="app-brand demo mt-4" style={{ margin: "auto" }}>
          <Link to="/user/dashboard" className="app-brand-link">
            <span className="app-brand-logo demo">
              <img
                className="mt-2 mb-2"
                src={btclogo}
                width="40px"
                alt="BTC Logo"
              />
            </span>
          </Link>
        </div>
        <p>
          <b>BTCASH</b>
        </p>

        <button
          className="layout-menu-toggle menu-link text-large ms-auto w-100"
          onClick={handleToggle}
          style={{ background: "none", border: "none" }}
        >
          {isOpen ? (
            <i className="bg-transparent d-flex justify-content-center align-items-center">
              <img
                src={rightI}
                alt=""
                className=""
                style={{ width: "22px", rotate: "180deg" }}
              />
            </i>
          ) : (
            <i className="bg-transparent d-flex justify-content-center align-items-center">
              <img src={rightI} alt="" className="" style={{ width: "22px" }} />
            </i>
          )}
        </button>

        <div className="menu-inner-shadow"></div>
        <ul className="menu-inner py-1">
          <li className="menu-item open">
            <Link
              to="/user/dashboard"
              className="menu-link sidemenu-dash"
              onClick={handleClickInside}
            >
              {/* <i class="menu-icon tf-icons ti ti-smart-home"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img
                  src={dashboardI}
                  alt=""
                  className=""
                  style={{ width: "22px" }}
                />
              </i>
              <div data-i18n="Dashboard">Dashboard</div>
            </Link>
          </li>
          <li className="menu-item">
            <Link
              to="/user/buyplan"
              className="menu-link sidemenu-dash"
              onClick={handleClickInside}
            >
              {/* <i className="fa-solid fa-bars"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img
                  src={rankI}
                  alt=""
                  className=""
                  style={{ width: "25px" }}
                />
              </i>
              <div data-i18n="Stake Plan">Stake Plan</div>
            </Link>
          </li>

          <li className="menu-item">
            <Link
              to="/user/staking-history"
              className="menu-link sidemenu-dash"
              onClick={handleClickInside}
            >
              {/* <i className="fa-solid fa-trophy"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img
                  src={rewardI}
                  alt=""
                  className=""
                  style={{ width: "25px" }}
                />
              </i>
              <div data-i18n="Staking History">Staking Reward </div>
            </Link>
          </li>

          <li className="menu-item">
            <Link
              to="/user/transaction"
              className="menu-link sidemenu-dash"
              onClick={handleClickInside}
            >
              {/* <i class="menu-icon tf-icons ti ti-text-wrap-disabled"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img
                  src={transactionI}
                  alt=""
                  className=""
                  style={{ width: "35px" }}
                />
              </i>
              <div data-i18n="Transactions">Transactions</div>
            </Link>
          </li>

          <li className="menu-item">
            <Link
              to="/user/wallet"
              className="menu-link sidemenu-dash"
              onClick={handleClickInside}
            >
              {/* <i class="menu-icon tf-icons ti ti-wallet"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img
                  src={walletI}
                  alt=""
                  className=""
                  style={{ width: "25px" }}
                />
              </i>
              <div data-i18n="Wallet">Wallet</div>
            </Link>
          </li>
          <li className="menu-item">
            <Link
              to="/user/fund/transfer"
              className="menu-link sidemenu-dash"
              onClick={handleClickInside}
            >
              {/* <i class="menu-icon tf-icons ti ti-layout-kanban"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img
                  src={fundI}
                  alt=""
                  className=""
                  style={{ width: "25px" }}
                />
              </i>
              <div data-i18n="Fund Transfer">Fund Transfer</div>
            </Link>
          </li>

          <li className="menu-item">
            <Link
              to="/user/my-team"
              className="menu-link sidemenu-dash"
              onClick={handleClickInside}
            >
              {/* <i class="menu-icon ti ti-network"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img
                  src={teamI}
                  alt=""
                  className=""
                  style={{ width: "25px" }}
                />
              </i>
              <div data-i18n="Team">Team</div>
            </Link>
          </li>

          <li className="menu-item">
            <Link
              to="/user/network"
              className="menu-link sidemenu-dash"
              onClick={handleClickInside}
            >
              {/* <i class="menu-icon ti ti-network"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img
                  src={networkI}
                  alt=""
                  className=""
                  style={{ width: "25px" }}
                />
              </i>
              <div data-i18n="Network">Network</div>
            </Link>
          </li>

          <li className="menu-item">
            <Link
              to="/user/support"
              className="menu-link sidemenu-dash"
              onClick={handleClickInside}
            >
              {/* <i className="fa-solid fa-question"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img
                  src={helpI}
                  alt=""
                  className=""
                  style={{ width: "25px" }}
                />
              </i>
              <div data-i18n="Help Desk">Help Desk</div>
            </Link>
          </li>

          <li className="menu-item">
            <Link className="menu-link sidemenu-dash" onClick={handleLogout}>
              {/* <i className="menu-icon tf-icons ti ti-logout"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img
                  src={logoutI}
                  alt=""
                  className=""
                  style={{ width: "20px" }}
                />
              </i>
              <div data-i18n="Logout">Logout</div>
            </Link>
          </li>
          {/* <li>
          <div class="d-flex justify-content-center align-items-center px-0 w-100">
            <div className="w-100">
              <img src={sunI} alt="" style={{ width: '20px' }} />
            </div>
            <label class="switch switch-primary me-0">
              <input type="checkbox" class="switch-input" id="payment-terms" checked="" />
              <span class="switch-toggle-slider">
                <span class="switch-on"></span>
                <span class="switch-off"></span>
              </span>
            </label>
          </div>
        </li> */}
        </ul>
      </aside>

      <nav
        className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div
          className="navbar-nav-right d-flex align-items-center"
          id="navbar-collapse"
        >
          <div className="d-block d-lg-none d-md-none">
            <Link to="/user/dashboard" className="app-brand-link">
              <span className="app-brand-logo demo">
                <img
                  className="mt-2 mb-2"
                  src={btclogo}
                  width="40px"
                  alt="BTC Logo"
                />
              </span>
            </Link>
          </div>

          <div className="d-block d-lg-none d-md-none">
            <button
              className="layout-menu-toggle menu-link text-large ms-auto w-100"
              onClick={handleToggle}
              style={{ background: "none", border: "none" }}
            >
              {isOpen ? (
                <i
                  class="fa-solid fa-bars"
                  style={{ color: "#C4C4C4", fontSize: "1.3rem" }}
                ></i> // Close icon
              ) : (
                <i
                  class="fa-solid fa-bars"
                  style={{ color: "#C4C4C4", fontSize: "1.3rem" }}
                ></i> // Menu icon
              )}
            </button>
          </div>

          <div className="navbar-nav align-items-center">
            <div className="nav-item navbar-search-wrapper mb-0">
              <Link
                className="nav-item nav-link search-toggler d-flex align-items-center px-0"
                to="javascript:void(0);"
              >
                <div className="top-title-layout d-none d-md-inline-block text-muted fw-normal">
                  <span className="user-welcome">Welcome Back ,</span>
                  <span className="userEmail">
                    {" "}
                    {user?.email} ({user?.btucode})
                  </span>
                </div>
              </Link>
            </div>
          </div>

          <ul className="navbar-nav flex-row align-items-center ms-auto">
            <div>
              <li
                className="nav-item navbar-dropdown dropdown-user dropdown pt-2"
                style={{ marginInline: "35px" }}
              >
                <div class="d-flex justify-content-center align-items-center gap-2">
                  <div className="w-100">
                    <img src={sunI} alt="" style={{ width: "20px" }} />
                  </div>
                  <label class="switch switch-primary ">
                    <input
                      type="checkbox"
                      class="switch-input"
                      id="modeChanges"
                      onChange={changeMode}
                    />
                    <span class="switch-toggle-slider">
                      <span class="switch-on"></span>
                      <span class="switch-off"></span>
                    </span>
                  </label>
                </div>
              </li>

              <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-2">
                <a
                  className="nav-link btn btn-text-secondary btn-icon rounded-pill dropdown-toggle hide-arrow waves-effect waves-light"
                  href="javascript:void(0);"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  <span className="position-relative">
                    <i
                      className="ti ti-bell ti-md"
                      style={{ color: "#C4C4C4" }}
                    ></i>
                  </span>
                </a>
                <ul className="dropdown-menu dropdown-menu-end p-0">
                  <li className="dropdown-menu-header border-bottom">
                    <div className="dropdown-header d-flex align-items-center py-3">
                      <h6 className="mb-0 me-auto">Notification</h6>
                      <div className="d-flex align-items-center h6 mb-0">
                        <span className="badge bg-label-primary me-2">
                          {not?.length}
                        </span>
                        <a
                          href="javascript:void(0)"
                          className="btn btn-text-secondary rounded-pill btn-icon dropdown-notifications-all waves-effect waves-light"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          aria-label="Mark all as read"
                          data-bs-original-title="Mark all as read"
                        >
                          <i className="ti ti-mail-opened text-heading"></i>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li className="dropdown-notifications-list scrollable-container ps">
                    <ul className="list-group list-group-flush">
                      {not.map((items, i) => {
                        return (
                          <li
                            className="list-group-item list-group-item-action dropdown-notifications-item waves-effect waves-light"
                            key={i}
                          >
                            <div className="d-flex">
                              <div className="flex-shrink-0 me-3">
                                <div className="avatar">
                                <img
                      src={
                        user?.profileImg
                          ? `${Image}/uploads/${user?.profileImg}`
                          : `${Image}/uploads/default-avatar.jpg`
                      }
                      alt=""
                      className="rounded-circle"
                    />
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <h6 className="small mb-1">
                                  {items?.title} 🎉
                                </h6>
                                <small className="mb-1 d-block text-body">
                                  {items?.message}
                                </small>
                                <small className="text-muted">
                                  {moment(items.createdAt).fromNow()}
                                </small>
                              </div>
                              <div className="flex-shrink-0 dropdown-notifications-actions">
                                <a
                                  href="javascript:void(0)"
                                  className="dropdown-notifications-read"
                                >
                                  <span className="badge badge-dot"></span>
                                </a>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                </ul>
              </li>

              <li className="nav-item navbar-dropdown dropdown-user dropdown">
                <Link
                  className="nav-link dropdown-toggle hide-arrow p-0"
                  to="javascript:void(0);"
                  data-bs-toggle="dropdown"
                >
                  <div className="avatar avatar-online">
                    <img
                      src={
                        user?.profileImg
                          ? `${Image}/uploads/${user?.profileImg}`
                          : `${Image}/uploads/default-avatar.jpg`
                      }
                      alt=""
                      className="rounded-circle"
                    />
                  </div>
                </Link>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <Link
                      className="dropdown-item mt-0 waves-effect"
                      to="/user/profile"
                    >
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                          <div className="avatar avatar-online">
                            <img
                              src={
                                user?.profileImg
                                  ? `${Image}/uploads/${user?.profileImg}`
                                  : `${Image}/uploads/default-avatar.jpg`
                              }
                              alt=""
                              className="rounded-circle"
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mb-0">{user?.email}</h6>
                          <small className="text-muted">User</small>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <div className="dropdown-divider my-1 mx-n2"></div>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item waves-effect"
                      to="/user/profile"
                    >
                      <i className="ti ti-user me-3 ti-md"></i>
                      <span className="align-middle">My Profile</span>
                    </Link>
                  </li>
                  <li>
                    <div className="d-grid px-2 pt-2 pb-1">
                      <button
                        className="btn btn-sm btn-danger d-flex waves-effect waves-light"
                        onClick={handleLogout}
                      >
                        <small className="align-middle">Logout</small>
                        <i className="ti ti-logout ms-2 ti-14px"></i>
                      </button>

                      <SweetAlert2
                        {...swalProps}
                        didOpen={() => {
                          console.log("Alert opened");
                        }}
                        didClose={() => {
                          setSwalProps({ ...swalProps, show: false }); // Ensure state reset after dialog closes
                          console.log("Alert closed");
                        }}
                        onConfirm={() => {
                          confirmLogout(); // Call logout logic
                        }}
                        onCancel={() => {
                          handleCancel(); // Handle cancel logic
                        }}
                        onError={(error) => {
                          console.error("Error:", error);
                        }}
                        onResolve={(result) => {
                          console.log("Resolved:", result);
                        }}
                      />
                    </div>
                  </li>
                </ul>
              </li>
            </div>
          </ul>
        </div>
      </nav>
    </>
  );
}
