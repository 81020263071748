import React from 'react'
import TreeListComponent from '../../Components/Tree/TreeListComponent'

export default function TreePage() {
  return (
    <div>
      <TreeListComponent />
    </div>
  )
}
