import React from 'react'
import BlogListComponent from '../../../Components/Blog/Category/BlogListComponent'

export default function BlogListPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col">
                    <BlogListComponent />
                </div>
            </div>
        </div>
    )
}
