import React from 'react'
import FundForm from '../../Forms/Fund/FundForm'
import FundHistoryListComponent from '../../Components/Fund/FundHistoryListComponent'

export default function FundPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <FundForm />
                <FundHistoryListComponent/>
            </div>
        </div>
    )
}
