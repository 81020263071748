import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import { get_direct_busness, getDirectUserReferal, getPoolAnount } from '../../../common/Api';
import DataTable from 'react-data-table-component';
import moment from 'moment';

export default function DirectReferal() {
    const query = new URLSearchParams(useLocation().search);
    const myParam = query.get('level');

    const { user, token } = useSelector((state) => state.user);
    const { ucode } = useParams();
    const [level1amount, setLevel1Amount] = useState(0);
    const [level2amount, setLevel2Amount] = useState(0);
    const [level3amount, setLevel3Amount] = useState(0);
    const [sponseremail, setSpomserEmail] = useState('');
    const [users, setUsers] = useState([]);
    const [businessData, setBusinessData] = useState({}); // Store business data by user ID

    const getDirectReferalData = async () => {
        try {
            const data = await getDirectUserReferal(ucode ? ucode : user.btucode, token);
            console.log(data)
            setUsers(data.data);
            setSpomserEmail(data?.email)
        } catch (error) {
            console.log(error);
        }
    };

    const userPoolAmountData = async () => {
        try {
            const data = await getPoolAnount(user._id, token);
            setLevel1Amount(data.poolone);
            setLevel2Amount(data.pooltwo);
            setLevel3Amount(data.poolthree);
        } catch (error) {
            console.log(error);
        }
    };

    const getRankName = (rankId) => {
        const rankCritica = [
            { id: 1, name: "Basic Club" },
            { id: 2, name: "Meta Founder Club" },
            { id: 3, name: "Meta Star Club" },
            { id: 4, name: "Meta Royal Club" },
            { id: 5, name: "Meta Prime Club" },
            { id: 6, name: "Meta Ambassador Club" },
        ];
        const rank = rankCritica.find((r) => r.id === rankId);
        return rank ? rank.name : "-NA-"; // Return rank name or '-NA-' if not found
    };

    const getDirectBussness = async (id) => {
        try {
            const data = await get_direct_busness(id);
            return data.amount || 0; // Ensure to return amount or 0
        } catch (error) {
            console.log(error);
            return 0; // Return 0 in case of error
        }
    };

    useEffect(() => {
        userPoolAmountData();
        getDirectReferalData();
    }, [ucode]);

    useEffect(() => {
        const fetchBusinessData = async () => {
            const businessDataMap = {};
            for (const user of users) {
                const businessAmount = await getDirectBussness(user._id);
                businessDataMap[user._id] = businessAmount; // Store amount keyed by user ID
            }
            setBusinessData(businessDataMap); // Set the complete business data
        };

        if (users.length > 0) {
            fetchBusinessData();
        }
    }, [users]);

    const columns = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: "Username",
            selector: (row) => (
                <Link to={`/user/direct-network/${row.btucode}?level=${myParam ? Number(myParam) + 1 : 1}`} style={{ color: "white" }}>
                    {row.username} <i className="ti ti-arrow-down"></i>
                </Link>
            ),
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: "Sponser Email",
            selector: (row) => <>
                {sponseremail && sponseremail}
            </>,
            sortable: true,
        },
        {
            name: "Level",
            selector: (row) => row.btLevel,
            sortable: true,
        },
        {
            name: "Rank",
            selector: (row) => getRankName(row.btRank) || "-NA-",
            sortable: true,
        },
        {
            name: "Total Staking",
            selector: (row) => businessData[row._id] || 0, // Use businessData state
            sortable: true,
        },
        {
            name: "Joining Date",
            selector: (row) => moment(row.createdAt).format('dddd, MMMM Do YYYY'),
            sortable: true,
        },
    ];

    return (
        <div className='mt-2'>
            <div className='row'>
                <div className="col-lg-4">
                    <div className="level">Level 1: {level1amount} metabt</div>
                </div>
                <div className="col-lg-4">
                    <div className="level">Level 2: {level2amount} metabt</div>
                </div>
                <div className="col-lg-4">
                    <div className="level">Level 3: {level3amount} metabt</div>
                </div>
            </div>

            <h5 className='mt-2 ml-3 text-white'>{myParam ? `Level ${myParam} Referrals` : 'Direct Referrals'}</h5>
            <DataTable
                columns={columns}
                data={users}
                pagination
                highlightOnHover
                striped
            />
        </div>
    );
}
