import React from 'react'
import WalletPageComponent from '../../Component/Wallet/WalletPageComponent'

export default function WalletPage() {
  return (
    <div>
      <WalletPageComponent />
    </div>
  )
}
