import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  get_single_user,
  update_user_profile,
  update_user_profile_image,
  update_user_profile_password,
  sendOtpForProfileUpdate,
} from "../../../common/Api";
import { url, Image } from "../../../Components/Common/Url";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { ProgressBar } from "react-bootstrap";

export default function ProfileForm() {
  const { user, token } = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState("Tab1");
  const [value, setValue] = useState();

  const [profileimage, setProfileImage] = useState(null);
  const [isEditing, setIsEditing] = useState(true);

  const [otpSent, setOtpSent] = useState(false); // New state for OTP status
  const [profileCompleteness, setprofileCompleteness] = useState(""); // State for storing OTP input

  const [formValues, setFormValues] = useState({
    username: "",
    email: "",
    mobile: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pin: "",
    koinparkWallet: "",
    walletAddress: "",
    profileImg: null,
    gender: "",
    dob: "",
    twitter: "",
    telegram: "",
    instagram: "",
    lastname: "",
    otp: "",
  });

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveClick = (event) => {
    event.preventDefault();
    setIsEditing(false);
    // Handle save logic here if needed
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = async (event) => {
    try {
      setProfileImage(event.target.files[0]);
      const formData = new FormData();
      formData.append("image", event.target.files[0]);
      const data = await update_user_profile_image(user._id, token, formData);
      if (data.status == true) {
        toast.success(data.message);
        get_seing_user();
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const calculateProfileCompleteness = (formValues) => {
    const totalFields = 10; // Total number of fields (including otp if needed)
    let filledFields = 0;

    // Check each field in formValues
    for (const key in formValues) {

      if (formValues[key] && formValues[key] !== "otp") {
        // Check if the field is filled (non-empty)
        filledFields++;
      }
    }

    // Calculate completeness percentage
    const completenessPercentage = (filledFields / totalFields) * 100;

    // Optionally, you can round it to the nearest whole number
    return Math.round(completenessPercentage);
  };

  const get_seing_user = async () => {
    try {
      const data = await get_single_user(user._id, token);
      if (data && data.data && data.data.profileImg) {
        setProfileImage(data.data.profileImg);
      } else {
        setProfileImage("default-avatar.jpg");
      }

      setFormValues({
        username: data.data.username || "",
        email: data.data.email || "",
        mobile: data.data.btmobile || "",
        walletAddress: data.data.btwalletAddress || "",
        gender: data.data.gender || "",
        dob: data.data.dob || "",
        twitter: data.data.twitter || "",
        telegram: data.data.telegram || "",
        instagram: data.data.instagram || "",
        otp: "",
        lastname: data.data.lastname || "",
      });

      const profileCompleteness = calculateProfileCompleteness({
        username: data.data.username || "",
        email: data.data.email || "",
        mobile: data.data.btmobile || "",
        walletAddress: data.data.btwalletAddress || "",
        gender: data.data.gender || "",
        dob: data.data.dob || "",
        twitter: data.data.twitter || "",
        telegram: data.data.telegram || "",
        instagram: data.data.instagram || "",
        otp: "",
        lastname: data.data.lastname || "",
      });

      setprofileCompleteness(profileCompleteness);
      console.log(`Profile Completeness: ${profileCompleteness}%`);
    } catch (error) {}
  };
  useEffect(() => {
    if (user) {
      get_seing_user();

      
     
    }
  }, [user]); // Runs when 'user' changes

  const handlupdateProfile = async () => {
    if (!otpSent) {
      // Send OTP first
      try {
        const response = await sendOtpForProfileUpdate(user._id, token);
        if (response.status === true) {
          toast.success("OTP sent to your registered email");
          setOtpSent(true);
        } else {
          toast.error("Failed to send OTP");
          return; // Stop further execution if OTP fails to send
        }
      } catch (error) {
        toast.error("Error sending OTP");
        return; // Stop further execution if there was an error sending OTP
      }
    } else {
      const formData = new FormData();

      // Append form values to FormData
      for (const key in formValues) {
        formData.append(key, formValues[key]);
      }

      if (formValues.username == "") {
        toast.error("Please enter your first name.");
        return;
      }

      if (formValues.otp == "") {
        toast.error("OTP is required to proceed.");
        return;
      }

      // Verify OTP
      try {
        const data = await update_user_profile(formData, token, user._id);

        console.log(data);

        if (data.status === true) {
          toast.success(data.message);
          get_seing_user(); // Refresh user data
        } else {
          toast.error(data.response.data.message);
        }
      } catch (error) {
        toast.error("Error updating profile");
      }
    }
  };

  const changePwdValidationSchema = Yup.object().shape({
    cpassword: Yup.string().required("Please enter your current password"),
    npassword: Yup.string()
      .required("Please enter new password")
      .test(
        "len",
        "The password must be between 8 and 40 characters.",
        (val) =>
          val && val.toString().length >= 6 && val.toString().length <= 40
      ),
    vpassword: Yup.string()
      .required("Please retype your password.")
      .oneOf([Yup.ref("npassword"), null], "Your passwords do not match."),
  });

  const handleSubmitPasswordUpdate = async (passwords, actions) => {
    toast.dismiss();
    try {
      const data = await update_user_profile_password(
        passwords,
        token,
        user._id
      );
      console.log("data", data);
      if (data.status == true) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
        // toast.error("Failed to update password");
      }
      console.log();
    } catch (error) {
      toast.error("Failed to update password");
    } finally {
    }
  };

  return (
    <div className="prf">
      <div className="Profile_heading">
        <h2 className="text-white">Profile</h2>
      </div>
      <div className="profile_tabs">
        <div className="tab navs-tabs">
          <button
            className={`tablinks tab-link nav-item ${
              activeTab === "Tab1" ? "active" : ""
            }`}
            onClick={() => openTab("Tab1")}
          >
            Profile Details
          </button>
          {/* <button className={`tablinks tab-link nav-item ${activeTab === 'Tab2' ? 'active' : ''}`} onClick={() => openTab('Tab2')}>
                    KYC
                </button> */}
          <button
            className={`tablinks tab-link nav-item ${
              activeTab === "Tab3" ? "active" : ""
            }`}
            onClick={() => openTab("Tab3")}
          >
            Change Password
          </button>
        </div>
      </div>
      <div className="profile_tab_content">
        {activeTab === "Tab1" && (
          <div id="Tab1" className="tabcontent">
            <div className="profile_bg">
              <div className="user-infoWrapper">
                <div class="user_img">
                  {profileimage && (
                    <img
                      src={`${Image}/uploads/${profileimage}`}
                      width="200px"
                      alt="Blog"
                    />
                  )}
                  <span class="show_online"></span>
                  <input
                    class="file-input"
                    type="file"
                    required=""
                    onChange={handleFileChange}
                    name="profileImg"
                  />
                  <br />
                </div>
                <div className="user_name">
                  <h3 className="mt-3 mb-0 text-white">{user?.username}</h3>
                  {/* <div className="profession">
                                        <img src="../assets/images/str_icon.PNG" alt="Icon" />
                                        <span>Meta Founder</span>
                                    </div> */}
                </div>
              </div>
            </div>
            <button id="editButton" onClick={handleEditClick}>
              <i className="fa-solid fa-pencil"></i>
            </button>
            <div className="w-75">
            <ProgressBar variant="success" now={profileCompleteness} />
            <p className="pt-2 text-white">Your Profile is {profileCompleteness}% Complete</p>
            </div>
            <form id="survey-form" onSubmit={handleSaveClick}>
              <div className="form-wrap py-5">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="username">First Name</label>
                      <input
                        type="text"
                        name="username"
                        id="username"
                        value={formValues.username}
                        onChange={handleChange}
                        className="form-control"
                        required
                        disabled={!isEditing}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="username">Last Name</label>
                      <input
                        type="text"
                        name="lastname"
                        id="lastname"
                        value={formValues.lastname}
                        onChange={handleChange}
                        className="form-control"
                        disabled={!isEditing}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="email">E-mail</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={formValues.email}
                        onChange={handleChange}
                        className="form-control"
                        required
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label id="mobile" for="mobile">
                        Mobile No
                      </label>
                      <PhoneInput
                        id="mobile"
                        name="mobile"
                        className="mb-0"
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="IN"
                        value={formValues.mobile}
                        onChange={(value) =>
                          setFormValues({ ...formValues, mobile: value })
                        }
                        disabled={!isEditing}
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label id="gender" for="gender">
                        Gender
                      </label>
                      <select
                        name="gender"
                        id="gender"
                        className="form-select"
                        value={formValues.gender}
                        onChange={handleChange}
                        disabled={!isEditing}
                      >
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label id="dob" for="dob">
                        Date of Birth
                      </label>
                      <input
                        type="date"
                        name="dob"
                        id="dob"
                        class="form-control"
                        value={formValues.dob}
                        onChange={handleChange}
                        disabled={!isEditing}
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label id="twitter" for="twitter">
                        Twitter
                      </label>
                      <input
                        type="url"
                        name="twitter"
                        id="twitter"
                        placeholder="Enter Your Twitter Address"
                        class="form-control"
                        value={formValues.twitter}
                        onChange={handleChange}
                        disabled={!isEditing}
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label id="telegram" for="telegram">
                        Telegram
                      </label>
                      <input
                        type="url"
                        name="telegram"
                        id="telegram"
                        placeholder="Enter Your Telegram Address"
                        class="form-control"
                        value={formValues.telegram}
                        onChange={handleChange}
                        disabled={!isEditing}
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label id="instagram" for="instagram">
                        Instagram
                      </label>
                      <input
                        type="url"
                        name="instagram"
                        id="instagram"
                        class="form-control"
                        placeholder="Enter Your Instagram Address"
                        value={formValues.instagram}
                        onChange={handleChange}
                        disabled={!isEditing}
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label id="state" for="pin">
                        BEP20 wallet address
                      </label>
                      <input
                        type="text"
                        name="walletAddress"
                        id="Wallet"
                        class="form-control"
                        placeholder="Enter Your Wallet Address"
                        value={formValues.walletAddress}
                        onChange={handleChange}
                        disabled={!isEditing}
                      />
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    style={{ display: otpSent ? "block" : "none" }}
                  >
                    <div className="form-group">
                      <label htmlFor="otp">Enter OTP</label>
                      <input
                        type="text"
                        name="otp"
                        id="otp"
                        value={formValues.otp}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter OTP Here.."
                        style={{ display: otpSent ? "block" : "none" }}
                      />
                    </div>
                  </div>
                </div>

                {/* Continue for other fields as needed */}

                <button
                  type="submit"
                  style={{ display: isEditing ? "inline-block" : "none" }}
                  onClick={handlupdateProfile}
                  className="submit-screen w-100 btn btn-primary py-3"
                >
                  {otpSent ? "Verify OTP & Save" : "Send OTP"}
                </button>
              </div>
            </form>
          </div>
        )}

        {activeTab === "Tab3" && (
          <div id="Tab3" className="tabcontent">
            <div className="change_password">
              <div className="row">
                <Formik
                  initialValues={{
                    cpassword: "",
                    npassword: "",
                    vpassword: "",
                  }}
                  validationSchema={changePwdValidationSchema}
                  onSubmit={handleSubmitPasswordUpdate}
                >
                  {({ handleChange, handleBlur, values, isSubmitting }) => (
                    <Form>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="cpassword">Old Password</label>
                          <Field
                            type="password"
                            name="cpassword"
                            placeholder="Old Password"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="cpassword"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="npassword">New Password</label>
                          <Field
                            type="password"
                            name="npassword"
                            placeholder="New Password"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="npassword"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="vpassword">Confirm Password</label>
                          <Field
                            type="password"
                            name="vpassword"
                            placeholder="Confirm Password"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="vpassword"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="submit-screen w-100 btn btn-primary py-3"
                          disabled={isSubmitting}
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
