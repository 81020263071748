import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';

const ImageUpload = ({ image, setImage }) => {
    console.log(setImage)
    const handleDrop = useCallback(acceptedFiles => {
        // Assuming you only want to handle one file at a time
        const file = acceptedFiles[0];
        if (file.type.startsWith('image/')) {
            // Do something with the valid PDF file
            console.log('Valid Image file:', file);
            setImage((prev) => ({ ...prev, image: file }));
        } else {
            // Handle invalid file type
            console.error('Invalid file type. Please upload a Image file.');
            toast.error(`Invalid file type. Please upload a Image file.`)
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: handleDrop
    });

    return (
        <div {...getRootProps()} style={dropzoneStyles}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop a image file here, or click to select one</p>
        </div>
    );
};

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
};

export default ImageUpload;
