import React from 'react'
import TransactionComponent from '../../Component/Transaction/TransactionComponent'

export default function TransactionPage() {
  return (
    <div>
      <TransactionComponent />
    </div>
  )
}
