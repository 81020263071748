import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../Services/UserSlices";
import { ToastContainer, toast } from "react-toastify";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import btlogo from "../../btclogo.png";
import { send_user_forget_mail } from "../../common/Api";
import Swal from "sweetalert2";

export default function ResetPassword() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const { token, user } = useSelector((state) => state.user);

  // CAPTCHA state
  const [captcha, setCaptcha] = useState("");
  const [captchaAnswer, setCaptchaAnswer] = useState("");

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptcha(`${num1} + ${num2}`);
    setCaptchaAnswer(num1 + num2);
  };

  const initialValues = {
    email: "",
    captchaInput:""
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Please enter user email")
      .email("Invalid email format"),
    captchaInput: Yup.string()
      .required("Please solve the CAPTCHA")
      .test("captcha-match", "CAPTCHA is incorrect", function (value) {
        return value == captchaAnswer; // Check if input matches answer
      }),
  });

  const handleLogin = async (formValue) => {
    const { email } = formValue;
    const submitData = {
      email: email,
    };
    const data = await send_user_forget_mail(submitData);

    if (data.status == true) {
      toast.success(data.message);
      initialValues.email("");
    } else {
      return Swal.fire({
        icon: "error",
        title: "",
        text: data.message,
      });
    }
  };

  useEffect(() => {
    if (token) {
      navigation("/admin/dashboard");
    }
    if (user?.isAdmin == "user") {
      navigation("/user/dashboard");
    }
  }, [token, navigation, user?.isAdmin]);

  return (
    <>
      <ToastContainer position="top-center" />
      <section class="login_form_sec">
        <div className="container-xxl">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner py-4">
              <div className="card">
                <div className="card-body">
                  <div class="d-flex logo justify-content-center pb-3">
                    <img src={btlogo} width="200px" class="logo" />
                    {/* <div class="logo-text text-white">
                                            <h3 class="mb-0">BTCASH</h3>
                                            <p>BUILD YOUR OWN COMMUNITY</p>
                                        </div> */}
                  </div>
                  <p>Reset Your Password</p>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleLogin}
                  >
                    <Form className="mb-3">
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <Field
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="Enter your email"
                        />
                        <ErrorMessage
                          name="email"
                          component="span"
                          className="errorInput"
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          Solve CAPTCHA:{" "}
                          <span
                            style={{
                              background:
                                "linear-gradient(rgb(54, 47, 116), rgb(57, 34, 88))",
                              color: "white",
                              padding: "2px 4px",
                            }}
                          >
                            {captcha}{" "}
                          </span>
                        </label>
                        <Field
                          name="captchaInput"
                          type="text"
                          className="form-control"
                          placeholder="Enter the result"
                        />
                        <ErrorMessage
                          name="captchaInput"
                          component="span"
                          className="errorInput"
                        />
                      </div>

                      <div className="mb-3">
                        <button
                          type="submit"
                          className="btn btn-primary d-grid w-100 loginBtn d-flex gap-1 align-items-center justify-content-center"
                          disabled={loading}
                        >
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                          <span>Send Link</span>
                        </button>
                      </div>
                    </Form>
                  </Formik>
                  <div class="register">
                    <p>
                      Don't have an account?{" "}
                      <span>
                        {" "}
                        <Link to="/register">Register</Link>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
